<template>
  <suspense>
    <div>
      <router-view></router-view>
    </div>
  </suspense>
</template>

<script>
export default {
  name: "App",
};
</script>

<style></style>
