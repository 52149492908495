<template>
  <user-container>
    <div></div>
  </user-container>
</template>

<script>
// import { ref, onMounted, computed } from "vue";
export default {
  name: "UserProfile",
  setup() {
    return {};
  },
};
</script>
