<template>
  <navbar-comp></navbar-comp>
  <div class="h-screen md:p-4">
    <div class="flex flex-col md:flex-row rounded-lg bg-bg1 bg-opacity-80 dark:border-gray-700">
      <div class="w-full md:w-64 px-8 py-4 text-white flex flex-col border-r-1 border-slate-700 shadow-xl">
        <div class="flex flex-row justify-between items-center">
          <h1 class="text-2xl md:text-xl font-semibold">Mednemn</h1>
        </div>
        <nav class="mt-8">
          <router-link to="/" class="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700">Нүүр</router-link>
          <span class="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 cursor-pointer" @click="signOut">Гарах</span>
        </nav>
      </div>

      <div class="flex-grow p-6 overflow-y-auto">
        <div class="bg-white p-6 rounded shadow-md">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { clientInstance } from "@/libs/api/client";
export default {
  name: "UserProfile",

  setup() {
    // const router = useRouter();
    const store = useStore();
    const signOut = async () => {
      try {
        const res = await clientInstance.post("/user/auth/logout");
        if (res.status === 200) {
          localStorage.removeItem("user");
          localStorage.removeItem("isuser");
          localStorage.removeItem("expiresAt");
          store.commit("user/setUserName", null);
          window.location.href = "/";
        }
      } catch (error) {
        console.log(error);
      }
    };
    return {
      signOut,
    };
  },
};
</script>
