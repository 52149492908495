<template>
  <div>
    <navbar-comp></navbar-comp>
    <div class="min-h-screen max-w-[768px] mx-auto p-4">
      <p class="text-center text-lg text-slate-100 font-bold mb-4">Trivia Question & Answer</p>
      <!-- <p class="pl-4 text-slate-300 text-[13px] mb-2 uppercase">Төрөлүүд</p> -->
      <transition name="fade" mode="out-in">
        <div
          class="bg-bg1 bg-opacity-80 rounded-lg overflow-x-hidden overflow-y-scroll transition-all duration-300 ease-in-out h-[576px]"
          v-if="isLoaded"
        >
          <CategoryComp
            v-for="(category, index) in categoryData"
            :key="category.id"
            :category="category"
            :imagePath="imagePath[index]"
            :questionCount="category?.questionCount"
            :answeredQuestionCount="category?.answeredQuestionCount"
          />
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

import CategoryComp from "../components/game/CategoryComp.vue";
export default {
  name: "GamePage",
  components: {
    CategoryComp,
  },

  setup() {
    const store = useStore();
    const categoryData = ref([]);
    const isLoaded = ref(false);
    const imagePath = ref([
      {
        image: "history.png",
      },
      {
        image: "sports.png",
      },
      {
        image: "alterEgo.png",
      },
    ]);
    onMounted(async () => {
      const response = await store.dispatch("category/fetchCategory");
      if (response) {
        isLoaded.value = true;
      }
      categoryData.value = computed(() => store.getters["category/getCategory"]).value;
      console.log(categoryData.value);
    });

    return {
      categoryData,
      isLoaded,
      imagePath,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
