export const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

export const validatePassword = (password) => {
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&.]{8,}$/;
  return passwordRegex.test(password);
};

export const validateUserQuestionForm = (form) => {
  // const fields = ["lastName", "userName", "phoneNumber", "registerNumber", "address"];
  // const fieldName = ["Овог", "Нэр", "Утасны дугаар", "Регистрийн дугаар", "Хаяг"];
  const fieldDescription = [
    { key: "lastName", name: "Овог" },
    { key: "userName", name: "Нэр" },
    { key: "gender", name: "Хүйс" },
    { key: "position", name: "Албан тушаал" },
    { key: "phoneNumber", name: "Утасны дугаар" },
    { key: "registerNumber", name: "Регистрийн дугаар" },
    { key: "aimagHot", name: "Аймаг/Хот" },
    { key: "sumDuureg", name: "Сум/Дүүрэг" },
    { key: "address", name: "Хаяг" },
  ];

  const errors = {};

  fieldDescription.forEach((field) => {
    if (!form[field.key]) {
      errors[field.key] = `${field.name} оруулна уу.`;
    }
  });

  if (form.phoneNumber && !validatePhoneNumber(form.phoneNumber)) {
    errors.phoneNumber = "Утасны дугаар буруу байна.";
  }

  if (form.registerNumber && !validateRegisterNumber(form.registerNumber)) {
    errors.registerNumber = "Регистрийн дугаар буруу байна. (Жишээ нь: АА12345678)";
  }
  return errors;
};

const validatePhoneNumber = (phoneNumber) => {
  const phoneNumberRegex = /^[0-9]{8}$/;
  return phoneNumberRegex.test(phoneNumber);
};

const validateRegisterNumber = (registerNumber) => {
  const registerNumberRegex = /^[А-яЁё]{2}\d{8}$/;
  return registerNumberRegex.test(registerNumber);
};
