import { createStore } from "vuex";

import category from "./modules/category";
import gameplay from "./modules/gameplay";
import user from "./modules/user";
import home from "./modules/home";
import admin from "./modules/admin";

export default createStore({
  modules: {
    category,
    gameplay,
    user,
    home,
    admin,
  },
});
