<template>
    <div class="w-screen h-screen absolute z-30 bg-white bg-opacity-70 flex justify-center items-center">
        <div class="bg-green-600 p-4 text-center text-white ">
            <p class="font-bold text-3xl">Content here</p>
            <p class="my-4 font-bold text-3xl">Score {{ percent }}</p>

            <!-- buttons -->
            <div class="flex justify-around">
                <!-- <div class="rounded-full py-1 w-28 border cursor-pointer hover:text-black hover:bg-white">Done</div> -->
                <div class="rounded-full py-1 w-28 border cursor-pointer hover:text-black hover:bg-white" @click="$emit('restartQuiz')">Retry</div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: ['percent'],
    setup(){

    },
}
    
</script>