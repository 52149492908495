<template>
  <div class="bg-gray-100 h-screen flex justify-center items-center">
    <div class="bg-white p-8 rounded-lg shadow-md w-full max-w-xs">
      <h1 class="text-2xl font-semibold mb-4">Admin Login</h1>
      <div class="mb-4">
        <label for="username" class="block text-sm font-medium text-gray-700">Username</label>
        <input
          type="text"
          id="username"
          name="username"
          class="mt-1 p-2 w-full border rounded-md focus:ring focus:ring-indigo-300"
          v-model="username"
        />
      </div>
      <div class="mb-4">
        <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
        <input
          type="password"
          id="password"
          name="password"
          class="mt-1 p-2 w-full border rounded-md focus:ring focus:ring-indigo-300"
          v-model="password"
        />
      </div>
      <button
        type="submit"
        class="bg-indigo-500 text-white p-2 rounded-md w-full hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300"
        @click="login"
      >
        Login
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { adminInstance } from "@/libs/api/admin";
export default {
  setup() {
    const router = useRouter();
    const username = ref("");
    const password = ref("");

    const login = async () => {
      try {
        const response = await adminInstance.post("/login", {
          username: username.value,
          password: password.value,
        });

        if (response.status === 200) {
          router.push("/admin/userlist");
        }
      } catch (error) {
        console.log(error);
      }
    };

    return {
      username,
      password,
      login,
    };
  },
};
</script>
