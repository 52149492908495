<template>
  <navbar-comp></navbar-comp>
  <main class="flex h-screen items-center justify-center">
    <!-- quiz overlay -->
    <QuizComplete v-if="endOfQuiz" :percent="percentageScore" @restartQuiz="onQuizStart"></QuizComplete>

    <!-- quiz container -->
    <div class="bg-white flex-none container shadow-lg rounded-lg px-6 py-6">
      <!-- score container -->
      <div class="text-right text-gray-800">
        <p class="text-sm leading-3">Score</p>
        <p class="font-bold">{{ score }}</p>
      </div>

      <!-- timer container -->
      <div class="bg-gray-300 shadow-lg p-1 rounded-full w-full h-5">
        <div class="bg-blue-700 rounded-full w-11/12 h-full" :style="`width: ${timer}%`"></div>
      </div>

      <!-- question container -->
      <div class="rounded-lg bg-gray-300 p-1 neumorph-1 text-center font-bold text-gray-800 mt-2">
        <div class="bg-white p-5 rounded-md">
          {{ currentQuestion.question }}
        </div>
      </div>

      <!-- options container -->
      <div class="mt-8">
        <!-- option container -->

        <div v-for="(choice, item) in currentQuestion.choices" :key="item">
          <div class="neumorph-1 option-default bg-gray-300 p-1 rounded-lg mb-3 relative" :ref="optionChosen" @click="onOptionClicked(choice, item)">
            <div class="bg-blue-600 p-1 transform rotate-45 rounded-md h-10 w-10 text-white font-bold absolute right-0 top-0 shadow-lg">
              <p class="transform -rotate-45">+10</p>
            </div>
            <div class="rounded-lg font-bold flex p-2 text-white">
              <!-- option id -->
              <div class="p-3 rounded-lg">
                {{ item }}
              </div>
              <!-- option name -->
              <div class="flex items-center pl-6">{{ choice }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- progress indicator container -->
      <div class="mt-8 text-center">
        <div class="h-1 w-12 bg-gray-600 rounded-full mx-auto"></div>
        <p class="font-bold text-gray-600">{{ questionCounter }}/{{ questions.length }}</p>
      </div>
    </div>
  </main>
</template>

<style scoped>
.neumorph-1 {
  box-shadow: 6px 6px 18px rgba(0, 0, 0, 0.09), -6px -6px 18px #ffffff;
}
.container {
  max-width: 400px;
  border-radius: 25px;
}
</style>

<script>
import { onMounted, ref } from "vue";
import QuizComplete from "./QuizComplete.vue";

export default {
  setup() {
    //data
    let percentageScore = ref(0);
    let endOfQuiz = ref(false);
    let timer = ref(100);
    let score = ref(0);
    let canClick = true;
    let questionCounter = ref(0);
    const currentQuestion = ref({
      question: "",
      answer: 1,
      choices: [],
    });

    const questions = [];

    const loadQuestion = () => {
      canClick = true;
      itemsRef = [];
      //check if there are more questions to load
      if (questions.length > questionCounter.value) {
        //load question
        timer.value = 100;
        countDownTimer();
        currentQuestion.value = questions[questionCounter.value];
        console.log("Current questions", currentQuestion.value);
        questionCounter.value++;
      } else {
        //no more questions
        // endOfQuiz.value = true;
        onQuizEnd();
        // console.log("out of the questions")
      }
    };

    const onOptionClicked = (choice, item) => {
      if (canClick) {
        //TODO select an option
        const divContainer = itemsRef[item];
        const optioId = item + 1;
        if (currentQuestion.value.answer === optioId) {
          console.log("Answer is correct");
          score.value = score.value + 10;
          divContainer.classList.add("option-correct");
          divContainer.classList.remove("option-default");
        } else {
          console.log("Answer is incorrect");
          console.log(choice, item);
          divContainer.classList.add("option-wrong");
          divContainer.classList.remove("option-default");
        }
        // timer.value = 100;
        canClick = false;
        //TODO go to next question
        clearSelected(divContainer);
        console.log(choice, item);
      } else {
        //can't select option
        console.log("can't select question");
      }
    };

    const clearSelected = (divSelected) => {
      setTimeout(() => {
        divSelected.classList.remove("option-correct");
        divSelected.classList.remove("option-wrong");
        divSelected.classList.add("option-default");
        loadQuestion();
      }, 800);
    };

    let itemsRef = [];
    const optionChosen = (element) => {
      if (element) {
        itemsRef.push(element);
      }
    };

    const countDownTimer = function () {
      let interVal = setInterval(() => {
        if (timer.value > 0) {
          timer.value--;
        } else {
          console.log("timer is up");
          // onQuizEnd();
          clearInterval(interVal);
          loadQuestion();
        }
      }, 1000);
    };

    const fetchQuestionsFromServer = async function () {
      console.log("fetch questions from server");
      fetch("https://opentdb.com/api.php?amount=10&category=9&difficulty=easy")
        .then((res) => {
          // console.log(res);
          return res.json();
        })
        .then((data) => {
          // map json to fit our own arragnhement
          const newQuestions = data.results.map((serverQuestion) => {
            const arrangedQuestion = {
              question: serverQuestion.question,
              choices: "",
              answer: "",
            };

            const choices = serverQuestion.incorrect_answers;
            arrangedQuestion.answer = Math.floor(Math.random() * 4 + 1);

            choices.splice(arrangedQuestion.answer - 1, 0, serverQuestion.correct_answer);

            arrangedQuestion.choices = choices;

            return arrangedQuestion;
          });
          console.log("new formatted question", newQuestions);
          questions.push(...newQuestions);
          loadQuestion();
          countDownTimer();
        });
    };

    const onQuizEnd = function () {
      //calculate the percentage
      percentageScore.value = (score.value / 100) * 100;

      //stop timer
      timer.value = 0;

      //show overlay
      endOfQuiz.value = true;
    };

    const onQuizStart = function () {
      //set default values
      percentageScore.value = 0;
      endOfQuiz.value = false;
      timer.value = 100;
      score.value = 0;
      canClick = true;
      questionCounter.value = 0;
      currentQuestion.value = {
        question: "",
        answer: 1,
        choices: [],
      };
      questions.value = [];

      //fetch questions from server
      fetchQuestionsFromServer();
    };

    //lifecycle hooks
    onMounted(() => {
      fetchQuestionsFromServer();
    });

    return {
      currentQuestion,
      questions,
      questionCounter,
      loadQuestion,
      onOptionClicked,
      optionChosen,
      score,
      timer,
      endOfQuiz,
      onQuizEnd,
      percentageScore,
      onQuizStart,
    };
  },

  // computed: {
  //   formattedQuestion(){

  //     return ''
  //   }
  // },

  components: {
    QuizComplete,
  },
};
</script>
