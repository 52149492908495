<template>
  <admin-page>
    <div class="p-4">
      <p class="mb-4">Тэмцээнд оруулах төрлөө сонгоно уу</p>
      <div>
        <hot-table :settings="hotSettings" :data="categories" v-if="isLoaded"></hot-table>
      </div>
    </div>
  </admin-page>
</template>
<script>
import { ref, onMounted } from "vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import { adminInstance } from "@/libs/api/admin";
registerAllModules();

export default {
  name: "AddCompetition",
  components: {
    HotTable,
  },
  setup() {
    const categories = ref([]);
    const isLoaded = ref(false);

    // methods
    const getCategories = async () => {
      try {
        const res = await adminInstance.get("/category");
        categories.value = res.data;
        isLoaded.value = true;
      } catch (err) {
        console.log(err);
      }
    };

    const updateCompetition = async (id, isCompetition) => {
      try {
        await adminInstance.put(`/category/${id}`, { isCompetition });
      } catch (err) {
        console.log(err);
      }
    };

    // config
    const hotSettings = {
      data: categories.value,
      colHeaders: ["Төрөл", ""],
      columns: [
        {
          data: "name",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center ">${value}</div>`;
          },
        },
        {
          data: "isCompetition",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center py-2">${
              value
                ? '<button class="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 transition-colors" id="add">Тэмцээнэаc хасах</button>'
                : '<button class="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600 transition-colors" id="remove">Тэмцээнд нэмэх</button>'
            }</div>`;
            if (!td._hasClickListener) {
              td.addEventListener("click", (e) => {
                if (e.target.id === "add") {
                  categories.value[row].isCompetition = false;
                  updateCompetition(categories.value[row].id, false);
                } else if (e.target.id === "remove") {
                  updateCompetition(categories.value[row].id, true);
                  categories.value[row].isCompetition = true;
                }
              });
              td._hasClickListener = true;
            }
          },
        },
      ],
      stretchH: "all",
      width: "100%",
      autoWrapRow: true,
      height: 500,
      rowHeaders: true,
      manualRowResize: true,
      manualColumnResize: true,
      manualRowMove: true,
      manualColumnMove: true,
      rowHeights: 30,
      colWidths: 150,
      filters: true,
      // contextMenu: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    onMounted(() => {
      getCategories();
    });

    return {
      categories,
      isLoaded,
      hotSettings,
    };
  },
};
</script>
