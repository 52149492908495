import axios from "axios";

let baseURL = "";

// if (process.env.NODE_ENV === "production") {
  baseURL = "https://medne.mn/api";
// } else {
//   baseURL = "http://localhost/api";
// }

export const clientInstance = axios.create({
  baseURL,
  withCredentials: true,
});

export const authClientInstance = axios.create({
  baseURL,
});

export const verificationClientInstance = async () => {
  const res = await clientInstance.get("/checkauth/token");
  if (res.data.valid) {
    return res.data;
  } else {
    return null;
  }
};
// authClientInstance.interceptors.request.use((config) => {
//   const token = getToken("token");
//   if (token) {
//     config.headers["Authorization"] = `Bearer ${token}`;
//   }
//   return config;
// });

// // get token from cookie
// const getToken = (cookieName) => {
//   const cookies = document.cookie.split("; ");
//   for (const cookie of cookies) {
//     const [name, value] = cookie.split("=");
//     if (name === cookieName) {
//       return value;
//     }
//   }
//   return null;
// };
