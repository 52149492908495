<template>
  <admin-page>
    <div class="p-8">
      <!-- <div>Баннер оруулах</div> -->
      <h1 class="uppercase text-lg">Баннер оруулах</h1>
      <div>
        <div class="w-[40%] mt-8">
          <label for="name" class="block mb-2 text-xs font-medium text-gray-900 dark:text-slate-600 uppercase">
            <span class="text-red-500">*</span> нэр</label
          >
          <input
            type="text"
            name="name"
            id="name"
            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Баннер нэр"
            v-model="banner.bannerName"
          />
        </div>

        <div class="w-[40%] mt-4">
          <label for="position" class="block mb-2 text-xs font-medium text-gray-900 dark:text-slate-600 uppercase">
            <span class="text-red-500">*</span> байршил</label
          >
          <select
            placeholder="Баннер байршил"
            id="selectOption"
            v-model="banner.bannerPosition"
            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="" disabled selected>Баннер байршил сонгох</option>
            <option value="Нүүр хуудас">Нүүр хуудас</option>
            <option value="Мэдээ хуудас">Мэдээ хуудас</option>
          </select>
        </div>

        <div class="w-[40%] mt-4">
          <label for="url" class="block mb-2 text-xs font-medium text-gray-900 dark:text-slate-600 uppercase">
            <span class="text-red-500">*</span> Url</label
          >
          <input
            type="text"
            name="url"
            id="url"
            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Баннер Url"
            v-model="banner.bannerUrl"
          />
        </div>

        <div class="w-[40%] mt-4">
          <label for="url" class="block mb-2 text-xs font-medium text-gray-900 dark:text-slate-600 uppercase">
            <span class="text-red-500">*</span> Зураг</label
          >
          <input type="file" @change="handleImageUpload" accept="image/*" class="hidden" id="fileInput" />
          <div>
            <label for="fileInput" class="upload-icon cursor-pointer" v-if="!bannerImg">
              <img src="../../assets/upload.svg" alt="Upload Image" />
            </label>
            <div class="border p-8 rounded-lg relative" v-else>
              <img :src="bannerImg" v-if="bannerImg" alt="Uploaded Image" class="h-48 w-full" />
              <div
                @click="
                  () => {
                    bannerImg = null;
                  }
                "
              >
                <img src="../../assets/cancel.svg" alt="cancel" class="absolute top-1 right-2" />
              </div>
            </div>
          </div>
        </div>

        <div class="w-[40%] mt-6">
          <button class="bg-slate-700 py-2 px-8 rounded-xl text-white text-xs" @click="sumbitBanner">Илгээх</button>
        </div>
      </div>
    </div>
  </admin-page>
</template>

<script>
import { reactive, ref } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const bannerImg = ref(null);
    const banner = reactive({
      bannerName: "",
      bannerPosition: "",
      bannerUrl: "",
      imageUrl: null,
    });

    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        bannerImg.value = URL.createObjectURL(file);
        banner.imageUrl = file;
      }
    };

    const sumbitBanner = async () => {
      if (banner.bannerPosition && banner.imageUrl) {
        const formData = new FormData();

        formData.append("title", banner.bannerName);
        formData.append("position", banner.bannerPosition);
        formData.append("bannerUrl", banner.bannerUrl);
        formData.append("file", banner.imageUrl);

        // for (const [key, value] of formData.entries()) {
        //   console.log(`${key}: ${value}`);
        // }

        const res = await store.dispatch("admin/insertUpdateDeleteBanner", formData);
        if (res.status == 200) {
          alert("Ажилттай");
          window.location.reload();
        }
      } else {
        alert("Баннерын зураг болон байршлыг оруулна уу!");
      }
    };

    return {
      banner,
      handleImageUpload,
      sumbitBanner,
      bannerImg,
    };
  },
};
</script>
