<template>
  <admin-page>
    <div class="grid gap-2 w-[50%] p-4">
      <label for="">
        <h1 class="text-2xl font-semibold mb-4 text-center">Асуулт нэмэх</h1>
      </label>
      <label for="question" class="text-sm pl-2 text-slate-500">Асуултын түвшин</label>
      <select class="w-full p-2 border rounded shadow-md" @change="handleDifficultyChange" :value="questionDifficulty" id="question">
        <option value="" disabled selected hidden>Ангилал</option>
        <option value="1">Хялбар</option>
        <option value="2">Дунд</option>
        <option value="3">Хүндэвтэр</option>
        <option value="4">Хүнд</option>
      </select>

      <label for="question" class="text-sm pl-2 text-slate-500">Асуултын категори</label>
      <select class="w-full p-2 border rounded shadow-md" @change="handleCategoryChange" :value="questionCategory">
        <option value="" disabled selected hidden>Категори</option>
        <option v-for="(category, index) in categoryData" :key="index" :value="category.id">{{ category.name }}</option>
      </select>

      <label for="question" class="text-sm pl-2 text-slate-500">Асуулт</label>
      <input class="w-full p-2 border rounded shadow-md" type="text" placeholder="Асуулт" @change="question = $event.target.value" />
      <label for="question" class="text-sm pl-2 text-slate-500">Асуултын хариултууд</label>
      <input
        v-for="(option, index) in options"
        :key="index"
        class="w-full p-2 border rounded shadow-md"
        type="text"
        :placeholder="`Хариулт ${index + 1}`"
        @change="handleOptionChange($event, index)"
      />
      <!-- <input
        class="w-full p-2 border rounded"
        type="number"
        placeholder="Зөв хариултын дугаар"
        @change="updateCorrectAnswer"
        :value="correctAnswer.id"
      /> -->
      <label for="question" class="text-sm pl-2 text-slate-500">Зөв хариултын сонголт</label>
      <select class="w-full p-2 border rounded shadow-md" @change="updateCorrectAnswer" :value="correctAnswer">
        <option value="" disabled selected hidden>Зөв хариулт</option>
        <option v-for="(option, index) in options" :key="index" :value="option">{{ option }}</option>
      </select>

      <div class="flex justify-center mt-4">
        <button class="w-[100px] bg-blue-500 text-white p-2 rounded hover:bg-blue-600" @click="addQuestion">Нэмэх</button>
      </div>
    </div>
  </admin-page>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { adminInstance } from "@/libs/api/admin";

export default {
  name: "AddQuestion",
  setup() {
    const store = useStore();
    const question = ref("");
    const questionCategory = ref("");
    const questionDifficulty = ref("");
    const options = ref(["", "", "", ""]);
    const correctAnswer = ref("");
    const categoryData = ref([]);

    const handleCategoryChange = (e) => {
      questionCategory.value = e.target.value;
    };

    const handleDifficultyChange = (e) => {
      questionDifficulty.value = e.target.value;
    };

    const handleOptionChange = (e, index) => {
      options.value[index] = e.target.value;
    };

    const checkOptions = () => {
      return options.value.every((option) => option.option !== "");
    };

    onMounted(async () => {
      // const response =
      await store.dispatch("category/fetchCategory");
      // if (response) {
      //   isLoaded.value = true;
      // }
      categoryData.value = computed(() => store.getters["category/getCategory"]).value;
    });

    const updateCorrectAnswer = (e) => {
      if (!checkOptions()) {
        alert("Хариултуудыг бүрэн оруулна уу");
        return (e.target.value = "");
      }
      correctAnswer.value = e.target.value;
    };

    const addQuestion = async () => {
      // if (!question.value || !checkOptions() || !correctAnswer.value || !questionCategory.value || !questionDifficulty.value) {
      //   alert("Асуулт, хариултууд, зөв хариулт бүрэн оруулна уу");
      //   return;
      // }
      const res = await adminInstance.post("/add-question", {
        question: question.value,
        category: questionCategory.value,
        categoryId: questionCategory.value,
        difficulty: questionDifficulty.value,
        options: options.value,
        correctAnswer: correctAnswer.value,
      });
      console.log(res.data);
    };
    return {
      question,
      questionCategory,
      questionDifficulty,
      options,
      correctAnswer,
      categoryData,
      addQuestion,
      updateCorrectAnswer,
      handleOptionChange,
      handleCategoryChange,
      handleDifficultyChange,
    };
  },
};
</script>
