<template>
  <navbar-comp></navbar-comp>

  <div class="bg-[#0A0021] font-sans text-[#D6DCDC]">
    <div class="xl:mx-16 md:mx-6 mx-2 flex flex-col gap-10">
      <div class="mt-16 flex flex-col gap-12 items-center">
        <!-- <div class="grid col-span-1 md:grid-cols-2 gap-6">
          <div class="flex gap-6 items-start">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque possimus consequatur cumque, modi tenetur eum veniam quae suscipit esse
              itaque quis perspiciatis eos voluptate sint expedita in nemo beatae? Et.
            </p>
            <img src="../assets/cancel.svg" alt="" />
          </div>
          <div class="flex gap-6 items-start">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque possimus consequatur cumque, modi tenetur eum veniam quae suscipit esse
              itaque quis perspiciatis eos voluptate sint expedita in nemo beatae? Et.
            </p>
            <img src="../assets/cancel.svg" alt="" />
          </div>
        </div> -->

        <div class="uppercase bg-slate-100 rounded-lg p-8 text-center text-black">
          <p class="font-bold mb-6">санал хүсэлт</p>
          <input type="text" class="w-full p-3 bg-slate-100 border-b-2 border-b-gray-300" placeholder="Нэр" />
          <textarea
            name="feedback"
            id="feedback"
            cols="30"
            rows="10"
            class="p-3 w-full bg-slate-100 h-32 border-b-2 border-b-gray-300 my-2"
            placeholder="Санал хүсэлтээ бичнэ үү!"
          ></textarea>
          <button class="bg-gray-200 w-full p-3 text-black text-sm font-bold rounded-lg text-center">Илгээх</button>
        </div>
      </div>
      <TheFooter :data="banner" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import TheFooter from "@/components/ui/TheFooter.vue";
export default {
  name: "FeedBack",
  components: { TheFooter },
  setup() {
    const store = useStore();
    const banner = ref([]);
    const error = ref(null);

    onMounted(async () => {
      try {
        await store.dispatch("home/fetchBanners");

        banner.value = computed(() => store.getters["home/getHomeBanners"]).value;
      } catch (err) {
        error.value = "Алдаа гарлаа. Хуудсыг дахин ачааллана уу.";
        console.log(err);
      }
    });
    return { banner };
  },
};
</script>
