<template>
  <admin-page>
    <div class="mb-4">
      <p class="text-center">Бүртгэлтэй хэрэглэгчдийн жагсаалт</p>
    </div>

    <div class="px-4" v-if="isLoaded">
      <hot-table :settings="hotSettings" :data="userList"></hot-table>
    </div>
  </admin-page>
</template>

<script>
import { ref, onMounted } from "vue";
import { adminInstance } from "@/libs/api/admin";

import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
registerAllModules();

export default {
  name: "AdminUser",
  components: {
    HotTable,
  },
  setup() {
    const userList = ref([]);
    const isLoaded = ref(false);
    const getUserList = async () => {
      try {
        const res = await adminInstance.get("/user");
        userList.value = res.data;
        userList.value.forEach((user) => {
          user.created_at = new Date(user.created_at).toLocaleString();
        });

        // Sort the scores in descending order
        userList.value.sort((a, b) => b.score - a.score); // <-- Add this line

        isLoaded.value = true;
      } catch (err) {
        console.log(err);
      }
    };

    onMounted(() => {
      getUserList();
    });

    const hotSettings = {
      data: userList.value,
      colHeaders: ["Нэр", "Хүйс", "Регистрын дугаар", "Албан тушаал", "Имэйл", "Утас", "Хаяг", "Оноо", "Бүртгүүлсэн огноо"],
      columns: [
        {
          data: "user_name",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center capitalize">${value}</div>`;
          },
        },
        {
          data: "gender",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center ">${value}</div>`;
          },
        },
        {
          data: "register_number",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center uppercase">${value}</div>`;
          },
        },
        {
          data: "position",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center ">${value}</div>`;
          },
        },
        {
          data: "email",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center ">${value}</div>`;
          },
        },
        {
          data: "phone_number",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center ">${value}</div>`;
          },
        },
        {
          data: "address",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center ">${value}</div>`;
          },
        },
        {
          data: "score",
          type: "numeric",
          numericFormat: {
            pattern: "0,00",
          },
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle", "font-bold");
            td.innerHTML = `<div class="flex justify-center items-center ">${value || 0}</div>`;
          },
        },
        {
          data: "created_at",
          type: "text",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center ">${value}</div>`;
          },
        },
      ],
      stretchH: "all",
      width: "100%",
      autoWrapRow: true,
      height: "auto",
      maxRows: 22,
      rowHeaders: true,
      manualRowResize: true,
      manualColumnResize: true,
      manualRowMove: true,
      manualColumnMove: true,
      rowHeights: 30,
      rowHeaderWidth: 50,
      contextMenu: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    return {
      userList,
      isLoaded,
      hotSettings,
    };
  },
};
</script>
