<template>
  <admin-page>
    <div class="px-8 py-4">
      <div class="flex justify-center items-center h-screen" v-if="isLoading">
        <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
      <div class="flex flex-col items-center h-screen" v-else-if="error">
        <h1 class="text-center text-red-500">{{ error }}</h1>
      </div>
      <div class="flex flex-col items-center h-screen">
        <div class="h-[800px] w-[800px]">
          <h1 class="text-center mb-4">Хүйс</h1>
          <canvas id="gender"></canvas>
        </div>
      </div>
    </div>
  </admin-page>
</template>

<script>
import { onMounted, ref } from "vue";
import { adminInstance } from "@/libs/api/admin";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  setup() {
    const gender = ref(null);
    const genderData = ref([]);

    const isLoading = ref(false);
    const error = ref(null);

    const fetchData = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get("/user/gender");
        genderData.value = res.data;
        isLoading.value = false;
      } catch (err) {
        isLoading.value = false;
        error.value = "Алдаа гарлаа";
        console.log(err);
      }
    };

    const createGenderChart = async () => {
      try {
        const chartData = {
          labels: genderData.value.map((item) => item.gender),
          datasets: [
            {
              data: genderData.value.map((item) => item.count),
              backgroundColor: [
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 99, 132, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                // ...
              ],
              borderColor: [
                "rgba(54, 162, 235, 1)",
                "rgba(255, 99, 132, 1)",
                "rgba(255, 206, 86, 1)",
                // ...
              ],
              borderWidth: 1,
            },
          ],
        };

        const chartOptions = {
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: 1.5,
          plugins: {
            datalabels: {
              color: "#000",
              textAlign: "center",
              font: {
                size: 14,
              },
              formatter: (value) => {
                return value + " хүн";
              },
            },
            legend: {
              position: "right",
              labels: {
                padding: 20,
              },
            },
          },
          layout: {
            padding: {
              top: 20,
              bottom: 20,
              left: 20,
              right: 20,
            },
          },
        };

        if (gender.value !== null) {
          gender.value.destroy();
        }

        const ctx = document.getElementById("gender").getContext("2d");
        gender.value = new Chart(ctx, {
          type: "pie",
          data: chartData,
          options: chartOptions,
          plugins: [ChartDataLabels], // Register the plugin
        });
      } catch (err) {
        error.value = "Алдаа гарлаа";
        console.log(err);
      }
    };

    onMounted(async () => {
      await fetchData();
      await createGenderChart();
    });

    return {
      isLoading,
      error,
    };
  },
};
</script>
