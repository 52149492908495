import axios from "axios";
let baseURL = "";
if (process.env.NODE_ENV === "production") {
  baseURL = "https://medne.mn/api/gameplay";
} else {
  baseURL = "http://localhost/api/gameplay";
}

export const gamePlayInstance = axios.create({
  baseURL,
  withCredentials: true,
});
