<template>
  <div>
    <navbar-comp></navbar-comp>
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0 relative">
      <!-- sign up -->
      <div class="w-full rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 bg-bg1 bg-opacity-80 dark:border-gray-700" v-if="isSignUp">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">Бүртгүүлэх</h1>
          <form class="space-y-4 md:space-y-6" action="#">
            <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">И-майл</label>
              <input
                type="email"
                name="email"
                id="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@company.com"
                required=""
                @change="handleEmailChange"
              />
              <span class="text-xs text-red-500" v-if="error.isNotValidEmail">Таны и-мэйл буруу байна засаж бичнэ үү</span>
            </div>
            <!--  IS ADDED TO USER QUESTION PAGE -->
            <!-- <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Нэр</label>
              <input
                type="text"
                name="username"
                id="username"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="user"
                required=""
                @change="handleUserNameChange"
              />
              <span class="text-xs text-red-500" v-if="error.isNotValidUserName">Таны нэр хоосон байна </span>
            </div> -->
            <div>
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Нууц үг</label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required=""
                @change="handlePasswordChange"
              />
              <span class="text-xs text-red-500" v-if="error.isNotValidPassword">Нууц үг буруу байна</span>
            </div>
            <div>
              <label for="confirm-password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Нууц үг давтах</label>
              <input
                type="password"
                name="confirm-password"
                id="confirm-password"
                placeholder="••••••••"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required=""
                @change="handlePasswordConfirmChange"
              />
              <span class="text-xs text-red-500" v-if="error.confirmPasswordNotMatch">Нууц үг таарахгүй байна</span>
            </div>

            <button
              type="submit"
              class="w-full flex items-center justify-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              @click="signUp"
            >
              <button-loading v-if="isLoading"></button-loading>
              <span> Бүртгүүлэх </span>
            </button>
            <div class="text-xs text-red-500 mb-2" v-if="error.errorOnSignUp">{{ error.errorOnSignUp }}</div>
            <p class="text-sm font-light text-gray-500 dark:text-gray-400">
              Бүртгүүлсэн бол
              <span href="#" class="font-medium text-primary-600 hover:underline dark:text-primary-500" @click="handleSignUp"> энд дарна уу </span>
            </p>
          </form>
        </div>
      </div>

      <!-- sign in -->
      <div
        class="w-full rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 bg-bg1 bg-opacity-80 dark:border-gray-700"
        v-if="!isSignUp && !isCodeVerify"
      >
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">Нэвтрэх</h1>
          <form class="space-y-4 md:space-y-6" action="#">
            <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">И-майл</label>
              <input
                type="email"
                name="email"
                id="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@company.com"
                required=""
                @change="handleEmailChange"
              />
            </div>
            <span class="text-xs text-red-500" v-if="error.isNotValidEmail">Таны и-мэйл буруу байна засаж бичнэ үү</span>
            <div>
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Нууц үг</label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required=""
                @change="handlePasswordChange"
              />
              <span class="text-xs text-red-500" v-if="error.errorOnLogin">{{ error.errorOnLogin }}</span>
            </div>

            <button
              type="submit"
              class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              @click="signIn"
            >
              <button-loading v-if="isLoading"></button-loading>
              <span> Нэвтрэх </span>
            </button>
            <div class="separator self-stretch text-gray-400 text-sm">Эсвэл</div>

            <div class="flex justify-center"><div id="googleSignIn" style=""></div></div>

            <p class="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
              Бүртгэлгүүлэх бол
              <span class="font-medium text-primary-600 hover:underline dark:text-primary-500" @click="handleSignUp">энд дарна уу</span>
            </p>
          </form>
        </div>
      </div>

      <!-- code verify -->
      <div class="w-full rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 bg-bg1 bg-opacity-80 dark:border-gray-700" v-if="isCodeVerify">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <!-- <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">Нэвтрэх</h1> -->
          <form class="space-y-4 md:space-y-6" action="#">
            <div>
              <label for="verification_code" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Баталгаажуулах код </label>
              <p class="text-xs text-gray-500 dark:text-gray-400 mb-2">{{ email }}-руу код илгээлээ</p>
              <input
                type="text"
                name="verification_code"
                id="verification_code"
                placeholder="••••••••"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                @change="handleVerifyCodeChange"
                :value="verificationCode"
              />
              <div class="flex justify-between items-center mt-4">
                <button @click="reSendCode">
                  <span class="text-xs text-gray-500 dark:text-gray-400">Код дахин илгээх</span>
                </button>
                <span class="text-xs text-red-500" v-if="error.verifyCodeNotMatch"> Баталгаажуулах код буруу байна </span>
              </div>
            </div>

            <button
              type="submit"
              class=""
              :disabled="!verificationCode"
              :class="{
                'w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800':
                  !isVerified,
                'w-full text-white bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600  dark:focus:ring-green-800 cursor-not-allowed':
                  isVerified,
              }"
              @click="verifyCode"
            >
              <button-loading v-if="isLoading"></button-loading>
              <span>
                {{ isVerified ? "Баталгаажсан" : "Баталгаажуулах" }}
              </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { validateEmail, validatePassword } from "@/libs/helper/validate";
import { clientInstance } from "@/libs/api/client";

import ButtonLoading from "@/components/ui/ButtonLoading.vue";

export default {
  components: {
    ButtonLoading,
  },
  name: "LoginPage",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const isSignUp = ref(false);
    const isCodeVerify = ref(false);
    const isVerified = ref(false);
    const isLoading = ref(false);
    const email = ref("");
    const userName = ref("");
    const password = ref("");
    const passwordConfirm = ref("");
    const verificationCode = ref("");
    const error = reactive({
      isNotValidEmail: false,
      isNotValidPassword: false,
      // isNotValidUserName: false,
      confirmPasswordNotMatch: false,
      verifyCodeNotMatch: false,
      errorOnLogin: "",
      errorOnSignUp: "",
    });

    // check token
    onMounted(async () => {
      initializeGoogleSignIn();
      try {
        if (route.params.token) {
          const res = await clientInstance.post("/user/auth/validate", { token: route.params.token });
          if (res.status === 200 && res.data.email) {
            isCodeVerify.value = true;
            isSignUp.value = false;
            email.value = res.data.email;
          }
        }
      } catch (err) {
        if (err.response.status === 400) router.push("/login");
        console.log(err);
      }
    });

    const initializeGoogleSignIn = () => {
      if (window.google && document.getElementById("googleSignIn") && !isSignUp.value) {
        window.google.accounts.id.initialize({
          client_id: "677235543627-k0kuc9d2e9hhb7h6gkt61i6tug9knj5u.apps.googleusercontent.com",
          callback: handleGoogleSignIn,
        });
        window.google.accounts.id.disableAutoSelect();

        window.google.accounts.id.renderButton(document.getElementById("googleSignIn"), {
          type: "standard",
          size: "large",
          shape: "rectangular",
          logo_alignment: "center",
        });
      }
    };

    // handle google sign in and sign up response from google api callback function in initializeGoogleSignIn function above
    const handleGoogleSignIn = async (response) => {
      isLoading.value = true;
      const res = await store.dispatch("user/googleLogin", {
        idToken: response.credential,
      });

      if (res.status === 200 && res.data.token) {
        if (res.data.is_entered_info) {
          router.push("/");
          console.log("is_entered_info", res.data.is_entered_info);

          localStorage.setItem("user", res.data.user_name);
          localStorage.setItem("isuser", res.data.is_entered_info);
        } else {
          router.push("/user/question");
        }
      } else if (res.status === 400) {
        error.errorOnLogin = JSON.parse(res.request.response).message;
      }
      isLoading.value = false;
    };

    // handle input change
    const handleEmailChange = (e) => {
      email.value = e.target.value;
      error.isNotValidEmail = false;
    };

    // const handleUserNameChange = (e) => {
    //   userName.value = e.target.value;
    //   // error.isNotValidUserName = false;
    // };

    const handlePasswordChange = (e) => {
      password.value = e.target.value;
      error.isNotValidPassword = false;
    };

    const handlePasswordConfirmChange = (e) => {
      passwordConfirm.value = e.target.value;
      error.confirmPasswordNotMatch = false;
    };

    const handleVerifyCodeChange = (e) => {
      verificationCode.value = e.target.value;
      error.verifyCodeNotMatch = false;
    };

    // reset input and error
    const resetInput = () => {
      email.value = "";
      password.value = "";
      passwordConfirm.value = "";
      userName.value = "";
      verificationCode.value = "";
    };

    const resetError = () => {
      console.log("reset error");

      error.isNotValidEmail = false;
      error.isNotValidPassword = false;
      error.confirmPasswordNotMatch = false;
      // error.isNotValidUserName = false;
      error.verifyCodeNotMatch = false;
      error.errorOnLogin = "";
      error.errorOnSignUp = "";
    };

    // handle sign in and sign up
    const signIn = async (e) => {
      resetError();
      e.preventDefault();
      if (!validateEmail(email.value)) {
        error.isNotValidEmail = true;
        return;
      }

      isLoading.value = true;
      const res = await store.dispatch("user/login", {
        email: email.value,
        password: password.value,
      });

      if (res.status === 200 && res.data.token) {
        if (res.data.is_entered_info) {
          router.push("/");
          localStorage.setItem("isuser", res.data.is_entered_info);
          console.log(res.data.is_entered_info);
        } else {
          router.push("/user/question");
        }
      } else if (res.status === 400) {
        error.errorOnLogin = JSON.parse(res.request.response).message;
        isLoading.value = false;
      } else {
        isLoading.value = false;
      }
    };

    const signUp = async (e) => {
      e.preventDefault();
      if (!validateEmail(email.value)) {
        error.isNotValidEmail = true;
      }
      if (!validatePassword(password.value)) {
        error.isNotValidPassword = true;
      }
      // if (!userName.value) {
      //   error.isNotValidUserName = true;
      // }
      if (!passwordConfirm.value) {
        error.confirmPasswordNotMatch = true;
        return;
      }
      if (password.value !== passwordConfirm.value) {
        error.confirmPasswordNotMatch = true;
        return;
      }
      try {
        isLoading.value = true;
        const res = await clientInstance.post("/user/auth/register/unverified", {
          email: email.value,
          password: password.value,
          userName: userName.value,
        });
        if (res.data.token) {
          router.push(`/login/token=${res.data.token}`);
          isCodeVerify.value = true;
          isSignUp.value = false;
          isLoading.value = false;
          // resetInput();
          resetError();
        }
      } catch (err) {
        if (err.response.status === 400) error.errorOnSignUp = JSON.parse(err.request.response).message;
        isLoading.value = false;
        console.log(err);
      }
    };

    // handle verify code
    const verifyCode = async (e) => {
      e.preventDefault();
      isLoading.value = true;
      try {
        const res = await clientInstance.post("/user/auth/register/verify", {
          token: route.params.token,
          verificationCode: verificationCode.value,
        });
        console.log(res);

        if (res.status === 201) {
          isVerified.value = true;
          isLoading.value = false;
          setTimeout(() => {
            router.push("/login");
            resetInput();
            resetError();
            isCodeVerify.value = false;
            isVerified.value = false;
          }, 3000);
        }
      } catch (err) {
        if (err.response.status === 400) error.verifyCodeNotMatch = true;
        isLoading.value = false;
        console.log(err);
      }
    };

    const reSendCode = async (e) => {
      e.preventDefault();
      try {
        const res = await clientInstance.post("/user/auth/register/resend", {
          email: email.value,
        });
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    };

    // switch between sign in and sign up
    const handleSignUp = () => {
      isSignUp.value = !isSignUp.value;
      resetInput();
      resetError();
      setTimeout(() => {
        initializeGoogleSignIn();
      }, 1000);
    };

    return {
      isSignUp,
      isCodeVerify,
      isLoading,
      isVerified,
      email,
      password,
      passwordConfirm,
      userName,
      verificationCode,
      error,
      handleSignUp,
      handleEmailChange,
      // handleUserNameChange,
      handlePasswordChange,
      handlePasswordConfirmChange,
      handleVerifyCodeChange,
      signIn,
      signUp,
      verifyCode,
      reSendCode,
    };
  },
};
</script>

<style>
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid currentColor;
}
.separator::before {
  margin-right: 0.25rem;
}
.separator::after {
  margin-left: 0.25rem;
}
</style>
