<template>
  <admin-page>
    <div v-if="BannerList && BannerList.length > 0" class="mt-6 flex flex-col gap-8 justify-center items-center">
      <h2 class="font-bold text-xl">Баннер жагсаалт</h2>

      <hot-table :settings="hotSettings" :data="BannerList"></hot-table>
    </div>
    <div v-else class="h-36 mt-20 text-center font-bold text-2lg text-slate-600">Баннер алга !</div>
  </admin-page>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";

registerAllModules();

export default {
  name: "BannerList",
  components: {
    HotTable,
  },
  setup() {
    const store = useStore();
    const BannerList = ref([]);

    onMounted(async () => {
      BannerList.value = await store.dispatch("admin/fetchBanners");
      console.log(BannerList.value);
    });

    const deleteBanner = async (id) => {
      try {
        const res = await store.dispatch("admin/insertUpdateDeleteBanner", { id: id });
        if (res.status === 200) {
          alert("Амжилттай устгалаа");
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
      }
    };

    const hotSettings = {
      data: BannerList.value,
      colHeaders: ["№", "Баннер нэр", "Баннер байршил", "Баннер url", "Баннер зураг", ""],
      columns: [
        {
          data: "id",
          type: "text",
          width: 20,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center capitalize">${value}</div>`;
          },
        },
        {
          data: "banner_name",
          type: "text",
          width: 70,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center capitalize">${value}</div>`;
          },
        },
        {
          data: "banner_position",
          type: "text",
          width: 70,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center capitalize">${value}</div>`;
          },
        },
        {
          data: "banner_url",
          type: "text",
          width: 70,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center ">${value}</div>`;
          },
        },
        {
          data: "banner_img",
          type: "text",
          width: 70,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<img class="flex justify-center items-center w-24 h-24 object-fit" src="${value}"></img>`;
          },
        },
        {
          data: "",
          width: 60,
          readOnly: true,
          renderer: function (instance, td) {
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center  gap-2">
                <button id="delete" class="bg-red-500 hover:bg-red-600 text-white font-bold  px-4 rounded focus:outline-none focus:shadow-outline">Устгах</button>
            </div>`;

            td.querySelector("#delete").addEventListener("click", () => {
              const id = BannerList.value[td.parentNode.rowIndex - 1].id;
              deleteBanner(id);
            });
          },
        },
      ],
      stretchH: "all",
      width: "1000px",
      autoWrapRow: true,
      height: "auto",
      maxRows: 1200,
      manualRowResize: true,
      manualColumnResize: true,
      manualRowMove: true,
      manualColumnMove: true,
      rowHeights: 30,
      colWidths: 150,
      rowHeaderWidth: 50,
      contextMenu: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    return {
      BannerList,
      hotSettings,
    };
  },
};
</script>
