<template>
  <div>
    <!-- SKELETON LOADING -->

    <!-- <div class="my-12">
      <div class="grid grid-cols-12 gap-12 mt-10">
        <div class="skeleton-loader col-span-5 h-6"></div>
        <div class="skeleton-loader col-span-7 h-6"></div>
      </div>

      <div class="grid grid-cols-12 gap-12 mt-10">
        <div class="skeleton-loader col-span-5 h-64 flex justify-center items-center">
          <svg
            class="w-12 h-12 text-gray-200 dark:text-gray-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path
              d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
            />
          </svg>
        </div>
        <div class="skeleton-loader col-span-7 h-64 flex justify-center items-center">
          <svg
            class="w-12 h-12 text-gray-200 dark:text-gray-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path
              d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
            />
          </svg>
        </div>
      </div>
    </div> -->

    <!-- QUIZ ************************** -->
    <div class="lg:grid grid-cols-12 gap-12 place-content-center lg:my-36">
      <div class="col-span-5">
        <h1 class="text-4xl font-bold uppercase mb-4 lg:mb-16 text-center text-[#4B965A]">Welcome to medne app</h1>
        <img src="../../assets/home-medne.webp" alt="" class="w-96 m-auto hidden lg:block" />
      </div>

      <div class="col-span-7">
        <!-- ZAAVAR -->
        <p class="text-justify">
          Мэднэ нь хүүхэд залууст зориулан бүтээгдсэн оюуны чадамж, авхаалж самбааг шаардсан асуулт хариултын арр юм. Илүү ихийг мэдэхийг хүсвэл мэднэ
          арр татаад тоглоорой.
        </p>
        <!-- GAME BODY -->
        <div class="border-2 p-4 md:p-4 border-[#3C3C3C] rounded-3xl mt-10">
          <div class="border rounded-2xl p-4 flex items-center bg-[#fafafa] text-black font-bold">
            <p class="p-2 h-10 text-center">1.</p>
            <p>NBA ямар үгсийн товчлол вэ?</p>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 my-4 md:my-6">
            <div class="border rounded-2xl p-4 flex gap-2 items-center hover:bg-[#4B965A] cursor-pointer">
              <p class="border rounded-full bg-[#fafafa] p-2 h-10 w-10 text-center text-black font-bold">A</p>
              <p>National Basketball Association</p>
            </div>
            <div class="border rounded-2xl p-4 flex gap-2 items-center cursor-pointer">
              <p class="border rounded-full bg-[#fafafa] p-2 h-10 w-10 text-center text-black font-bold">B</p>
              <p>National Basketball League</p>
            </div>
            <div class="border rounded-2xl p-4 flex gap-2 items-center cursor-pointer">
              <p class="border rounded-full bg-[#fafafa] p-2 h-10 w-10 text-center text-black font-bold">C</p>
              <p>International Basketball League</p>
            </div>
            <div class="border rounded-2xl p-4 flex gap-2 items-center cursor-pointer">
              <p class="border rounded-full bg-[#fafafa] p-2 h-10 w-10 text-center text-black font-bold">D</p>
              <p>International Basketball Association</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
