<template>
  <!-- <a :href="`/game/play/cid=${category?.id}`"> -->
  <router-link :to="`/game/play/cid=${category?.id}`">
    <transition name="fade">
      <div
        class="flex gap-4 items-center border-b-1 border-slate-600 py-2 px-4 hover:bg-slate-500 hover:cursor-pointer transition duration-300 ease-in-out"
      >
        <img class="h-8 w-8" :src="require(`../../assets/catergory_logos/${imagePath.image}`)" alt="" />
        <div class="w-full">
          <div class="w-full flex justify-between items-center">
            <div>
              <p class="text-slate-100 text-lg">{{ category?.name }}</p>
              <p class="text-slate-400 text-[13px]">{{ answeredQuestionCount }} / {{ questionCount }} хариулсан</p>
            </div>
            <font-awesome-icon icon="fa-solid fa-chevron-right" class="text-slate-600 text-[18px] cursor-pointer" />
          </div>
        </div>
      </div>
    </transition>
  </router-link>
  <!-- </a> -->
</template>
<script>
export default {
  name: "CategoryComp",
  props: {
    category: {
      type: Object,
      required: true,
    },
    imagePath: {
      type: Object,
      required: true,
    },
    questionCount: {
      type: String,
      required: true,
    },
    answeredQuestionCount: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>
