<template>
  <div>
    <navbar-comp></navbar-comp>
    <div class="min-h-screen max-w-[768px] mx-auto p-4 relative">
      <p class="text-center text-lg text-slate-100 font-bold mb-4">Trivia Question & Answer</p>
      <transition name="fade">
        <div class="h-[576px] bg-bg1 bg-opacity-80 rounded-lg overflow-x-hidden p-4" v-if="isLoaded">
          <div class="h-full flex flex-col relative" v-if="!isGameFinished">
            <p class="text-center text-slate-100 mb-2">Хугацаа: {{ countdown }} секунд</p>

            <!-- <div :class="`grid grid-flow-col grid-cols-[repeat(${10}, minmax(0, 1fr))] gap-1 w-full h-6 bg-gray-200 rounded-full p-1 mb-4`">
              <div
                v-for="(question, index) in questionData"
                :key="index"
                :ref="gameProgressIndicator"
                class="rounded-full transition-all duration-300 ease-in-out"
              ></div>
            </div> -->

            <div class="border-1 border-slate-600 rounded-lg p-4 mb-4 flex-1 flex justify-center items-center">
              <!-- {{ questionData }} -->
              <p class="text-xl text-slate-50 font-bold text-center transition-all duration-300 ease-in-out">{{ questionData?.questionText }}</p>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 flex-1 rounded-[30px]">
              <button
                v-for="(option, index) in questionData?.options"
                :key="index"
                class="flex justify-center items-center border-1 border-slate-600 rounded-[30px] p-4 transition-all duration-300 ease-in-out overflow-hidden"
                :ref="chosenAnswer"
                @click="checkAnswer(option.optionId, index)"
              >
                <span class="text-lg font-semibold text-center text-white">
                  {{ option.optionText }}
                </span>
              </button>
            </div>
          </div>

          <game-end v-if="isGameFinished" :gameScore="gameScore" @restartGame="restartGame"></game-end>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, onBeforeMount, onBeforeUnmount, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import GameEnd from "@/components/game/GameEnd.vue";
export default {
  components: {
    GameEnd,
  },
  name: "GamePlay",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const questionData = ref({});
    const currentQuestion = ref({});
    const isAnswered = ref(false);
    const gameScore = ref(0);
    const isGameFinished = ref(false);
    const isLoaded = ref(false);
    const answerRef = ref([]);
    const gameProgressRef = ref([]);

    const countdown = ref(5); // 5 seconds
    const difficulty = ref(1);
    const questionId = ref(null);
    const isCorrect = ref(false);

    const timer = ref(null);

    // initial first load of trivia question
    onMounted(() => {
      onTriviaStart();
      startTimer();
    });

    // check when trivia is finished
    onBeforeMount(() => {
      if (isGameFinished.value) {
        restartGame();
        clearInterval(timer.value);
      }
    });

    // restart game when component is unmounted
    onBeforeUnmount(() => {
      clearInterval(timer.value);
      restartGame();
    });

    //start timer
    const startTimer = () => {
      timer.value = setInterval(() => {
        countdown.value--;
        if (countdown.value <= 0) {
          moveToNextQuestion(isCorrect.value, difficulty.value, questionData.value?.questionId);
        }
      }, 1000);
    };

    // move to next question when timer is finished
    const moveToNextQuestion = (isCorrect, difficulty, questionId) => {
      clearInterval(timer.value);
      nextQuestion(isCorrect, difficulty, questionId); // your existing method
      resetTimer();
      startTimer();
    };

    // reset timer
    const resetTimer = () => {
      countdown.value = 5;
    };

    // watch if answer is answered and reset timer when answered
    watch(isAnswered, (newValue) => {
      if (newValue) {
        clearInterval(timer.value);
        resetTimer();
        startTimer();
      }
    });

    // options ref to get the chosen answer
    const chosenAnswer = (element) => {
      if (element) {
        answerRef.value.push(element);
      }
    };

    const gameProgressIndicator = (element) => {
      if (element) {
        gameProgressRef.value.push(element);
      }
    };

    // fetch trivia question from
    const onTriviaStart = async () => {
      const response = await store.dispatch("gameplay/fetchRandomQuestion", {
        categoryId: route.params.id,
        difficulty: difficulty.value,
        questionId: questionId.value,
      });
      // set question data
      questionData.value = computed(() => store.getters["gameplay/getQuestion"]).value;
      difficulty.value = questionData.value?.difficulty;
      questionId.value = questionData.value?.questionId;

      // check if trivia is finished or not and set isLoaded to true
      // if trivia is finished, set isGameFinished to true
      if (response?.data) {
        isLoaded.value = true;
        if (response?.data?.isFinished) {
          isGameFinished.value = true;
          gameScore.value = response?.data?.score;
          return;
        }
      }
    };

    // check if answer is correct or not
    const checkAnswer = async (answer, index) => {
      if (isAnswered.value) return;
      // find chosen answer div
      const chosenAnswerDiv = answerRef.value[index];
      // update difficulty and questionId
      difficulty.value = questionData.value?.difficulty;
      questionId.value = questionData.value?.questionId;
      // check if answer is correct or not from backend
      const response = await store.dispatch("gameplay/checkAnswer", {
        questionId: questionData.value?.questionId,
        answer: answer,
        categoryId: questionData.value?.categoryId,
      });

      // if answer is correct, add 1 to game score
      if (response?.isCorrect) {
        isCorrect.value = true;
        chosenAnswerDiv.classList.add("bg-green-500");
        gameScore.value++;
      } else {
        // if answer is wrong, add red background to chosen answer and green background to correct answer
        isCorrect.value = false;
        const correctAnswerIndex = questionData.value?.options.findIndex((option) => option?.id === response?.optionId);
        const correcAnswerDiv = answerRef.value[correctAnswerIndex];
        correcAnswerDiv.classList.add("bg-green-500");
        chosenAnswerDiv.classList.add("bg-red-500");
      }
      isAnswered.value = true;

      // wait for 1 second and load next question
      setTimeout(() => {
        nextQuestion(isCorrect.value, difficulty.value, questionId.value);
      }, 1000);
    };

    // loads next question if available
    const nextQuestion = async (correct, difficulty, questionId) => {
      resetAnswer();
      const response = await store.dispatch("gameplay/fetchRandomQuestion", {
        categoryId: route.params.id,
        difficulty: difficulty,
        questionId: questionId,
        isCorrect: correct,
      });

      if (response?.data?.isFinished) {
        isGameFinished.value = true;
        gameScore.value = response?.data?.score;
        clearInterval(timer.value);
        return;
      }

      questionData.value = computed(() => store.getters["gameplay/getQuestion"]).value;
    };

    // reset answer background
    const resetAnswer = () => {
      isAnswered.value = false;
      answerRef.value.forEach((answer) => {
        answer.classList.remove("bg-green-500");
        answer.classList.remove("bg-red-500");
      });
    };

    // restart game
    const restartGame = async () => {
      await router.push("/game/category");
      gameScore.value = 0;
      gameProgressRef.value = [];
      answerRef.value = [];
      // isGameFinished.value = false;
      resetAnswer();
      resetTimer();
    };
    return {
      isGameFinished,
      isLoaded,
      questionData,
      currentQuestion,
      gameScore,
      countdown,
      gameProgressIndicator,
      checkAnswer,
      chosenAnswer,
      restartGame,
    };
  },
};
</script>

<style scoped></style>
