<template>
  <div class="bg-[#0A0021] font-sans text-[#D6DCDC]">
    <div class="max-w-7xl mx-auto">
      <div class="flex items-center justify-between py-2 md:py-6 md:grid grid-cols-12 gap-12">
        <!-- Logo -->
        <div class="flex-shrink-0 md:col-span-5">
          <a href="/" class="col-span-5 flex gap-2 items-center">
            <img src="../../assets/logo.png" alt="" class="h-10 w-10" />
            <p class="text-lg">Medne</p>
          </a>
        </div>
        <!-- Navigation Links -->
        <div class="hidden md:block md:col-span-7">
          <div class="col-span-7 flex gap-10 justify-between">
            <ul class="text-base font-semibold flex-1 flex gap-2 justify-between items-center p-3 border-2 border-[#3C3C3C] rounded-full">
              <a href="/newsList">
                <li class="hover:text-[#4B965A]">Мэдээ</li>
              </a>
              <a @click="scrollToMerch()" class="cursor-pointer">
                <li class="hover:text-[#4B965A]">Мерч худалдаа</li>
              </a>
              <a href="/feedback">
                <li class="hover:text-[#4B965A]">Санал хүсэлт</li>
              </a>
              <a href="/">
                <li class="hover:text-[#4B965A]">Холбоо барих</li>
              </a>
            </ul>
            <div
              class="hidden xl:block border rounded-full py-3 px-6 hover:bg-white hover:text-black font-semibold cursor-pointer"
              @click="scrollToMerch()"
            >
              App татах
            </div>
          </div>
        </div>

        <!-- Mobile Menu (Hamburger) Button -->
        <div class="md:hidden">
          <button
            type="button"
            class="text-gray-300 hover:text-white focus:outline-none focus:text-white"
            aria-expanded="false"
            @click="toggleMobileMenu"
          >
            <span class="sr-only">Open main menu</span>
            <!-- Hamburger Icon -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile Menu (Hidden by default) -->
    <div :class="{ hidden: !mobileMenuOpen }" class="md:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <a href="/" class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Мэдээ</a>
        <a href="/" class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Мерч худалдаа</a>
        <a href="/" class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Холбоо барих</a>
        <a href="/" class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Санал хүсэлт</a>
        <button class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium" @click="signOut">App татах</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  emits: ["dataToParent"],
  setup(_, { emit }) {
    const router = useRouter();
    const mobileMenuOpen = ref(false);

    const toggleMobileMenu = () => {
      mobileMenuOpen.value = !mobileMenuOpen.value;
    };

    const scrollToMerch = () => {
      if (window.location.pathname === "/") {
        emit("dataToParent", true);
      } else {
        router.push("/");
      }
    };

    return {
      mobileMenuOpen,
      toggleMobileMenu,
      scrollToMerch,
    };
  },
};
</script>
