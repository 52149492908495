<template>
  <!-- BIDNII MERCH ********************** -->

  <div class="grid grid-cols-1 md:grid-cols-2 gap-6 place-items-center">
    <img src="../../assets/merchHoodie.png" alt="" class="w-full h-[16rem] md:h-[22rem] rounded-full" />
    <div class="p-2 text-justify">
      <h5 class="text-2xl font-bold">Medne Hoodie</h5>
      <p class="text-lg font-bold mb-4 mt-2">49,999₮</p>
      <p>
        This is a space to share more about the business: who's behind it, what it does and what this site has to offer. It’s an opportunity to tell
        the story behind the business or describe a special service or product it offers. You can use this section to share the company history or
        highlight a particular feature that sets it apart from competitors. Let the writing speak for itself. Keep a consistent tone and voice
        throughout the website to stay true to the brand image and give visitors a taste of the company’s values and personality.
      </p>
      <button class="border py-2 px-10 mt-10 rounded-full hover:bg-white hover:text-black font-bold">Buy</button>
    </div>
  </div>

  <!-- <div class="grid grid-cols-1 md:grid-cols-2 gap-6 place-items- my-6">
        <div class="skeleton-loader w-full h-[16rem] md:h-[22rem] rounded-full flex justify-center items-center">
          <svg
            class="w-12 h-12 text-gray-200 dark:text-gray-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path
              d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
            />
          </svg>
        </div>

        <div class="p-2 w-full">
          <div class="skeleton-loader h-8 mb-4"></div>
          <div class="skeleton-loader h-6 mb-2"></div>
          <div class="skeleton-loader h-8"></div>
          <div class="skeleton-loader h-32 mt-4"></div>
          <div class="skeleton-loader h-12 w-24 mt-10"></div>
        </div>
      </div> -->
</template>
