<template>
  <admin-page>
    <div class="px-8 py-4">
      <div class="flex justify-center items-center h-screen" v-if="isLoading">
        <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
      <div class="flex flex-col items-center h-screen" v-else-if="error">
        <h1 class="text-center text-red-500">{{ error }}</h1>
      </div>
      <div class="flex flex-col items-center h-screen">
        <div class="h-[800px] w-[800px]">
          <h1 class="text-center mb-4">Асуулт график</h1>
          <p class="text-right text-[#3c2480] text-xs" v-if="total.totalQuestion > 0">
            Нийт <span v-if="total.totalCategory > 0">{{ total.totalCategory }} төрлийн </span> {{ total.totalQuestion }} асуулт байна.
          </p>
          <canvas id="question"></canvas>
        </div>
      </div>
    </div>
  </admin-page>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { adminInstance } from "@/libs/api/admin";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  setup() {
    const question = ref(null);
    const questionData = ref([]);
    const total = reactive({
      totalQuestion: 0,
      totalCategory: 0,
    });

    const isLoading = ref(false);
    const error = ref(null);

    const fetchData = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get("/user/questionByCategory");
        questionData.value = res.data;
        res.data.map((item) => {
          total.totalCategory += 1;
          total.totalQuestion += parseInt(item.question_count, 10);
        });
        isLoading.value = false;
      } catch (err) {
        isLoading.value = false;
        error.value = "Алдаа гарлаа";
        console.log(err);
      }
    };

    const createQuestionChart = async () => {
      try {
        const chartData = {
          labels: questionData.value.map((item) => item.category),
          datasets: [
            {
              data: questionData.value.map((item) => item.question_count),
              backgroundColor: [
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 99, 132, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                // ...
              ],
              borderColor: [
                "rgba(54, 162, 235, 1)",
                "rgba(255, 99, 132, 1)",
                "rgba(255, 206, 86, 1)",
                // ...
              ],
              borderWidth: 1,
            },
          ],
        };

        const chartOptions = {
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: 1.5,
          plugins: {
            datalabels: {
              color: "#000",
              textAlign: "center",
              font: {
                size: 14,
              },
              formatter: (value) => {
                return value;
              },
            },
            legend: {
              display: false, // You can choose whether to display the legend or not
            },
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: "Төрөл",
                color: "#3c2480",
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: "Асуултын тоо",
                color: "#3c2480",
              },
            },
          },
          layout: {
            padding: {
              top: 20,
              bottom: 20,
              left: 20,
              right: 20,
            },
          },
        };

        if (question.value !== null) {
          question.value.destroy();
        }

        const ctx = document.getElementById("question").getContext("2d");
        question.value = new Chart(ctx, {
          type: "bar", // Change this to "bar" for a bar chart
          data: chartData,
          options: chartOptions,
          plugins: [ChartDataLabels],
        });
      } catch (err) {
        error.value = "Алдаа гарлаа";
        console.log(err);
      }
    };

    onMounted(async () => {
      await fetchData();
      await createQuestionChart();
    });

    return {
      isLoading,
      error,
      total,
    };
  },
};
</script>
