<template>
  <navbar-comp @dataToParent="receiveDataFromChild"></navbar-comp>
  <div class="bg-[#0A0021] font-sans text-[#D6DCDC]">
    <div class="xl:mx-16 md:mx-6 mx-2 flex flex-col gap-10">
      <QuizPart />

      <!-- NEWS ***************************** -->
      <div v-if="newsData.length > 0">
        <div class="flex justify-between items-center">
          <p class="font-bold uppercase text-lg">Мэдээ</p>
          <div class="flex gap-2 items-center cursor-pointer text-sm">
            <p>бүгдийг үзэх</p>
            <img src="../assets/right-chevron.svg" alt="" class="w-4 h-4" />
          </div>
        </div>
        <CartNews :data="newsData" />
      </div>
      <div class="my-6" v-else>
        <div class="flex justify-between items-center mb-4">
          <div class="skeleton-loader h-6 w-1/4"></div>
          <div class="skeleton-loader h-6 w-1/5"></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div class="h-64 skeleton-loader flex justify-center items-center">
            <svg
              class="w-12 h-12 text-gray-200 dark:text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path
                d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
              />
            </svg>
          </div>
          <div class="h-64 skeleton-loader flex justify-center items-center">
            <svg
              class="w-12 h-12 text-gray-200 dark:text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path
                d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
              />
            </svg>
          </div>
          <div class="h-64 skeleton-loader flex justify-center items-center">
            <svg
              class="w-12 h-12 text-gray-200 dark:text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path
                d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
              />
            </svg>
          </div>
        </div>
      </div>

      <!-- CATEGORY ************************* -->
      <div class="my-16">
        <div v-if="categoryData.length > 0">
          <p class="font-bold uppercase lg:text-lg mb-8">Төрлүүд</p>
          <div class="grid sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-10 xl:grid-cols-12 grid-cols-4">
            <div class="flex flex-col justify-center items-center gap-4 w-24" v-for="(item, index) in categoryData" :key="index">
              <img :src="item.image" :alt="item.id" class="w-16 h-auto" />
              <h6 class="text-xs font-bold text-center h-10 w-24">{{ item.category }}</h6>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-between items-center mb-4">
            <div class="skeleton-loader h-6 w-1/4"></div>
            <div class="skeleton-loader h-6 w-1/5"></div>
          </div>
          <div class="skeleton-loader h-64"></div>
        </div>
      </div>

      <div id="merch">
        <BidniiMerch />
      </div>

      <!-- DOWNLOAD APP -->
      <div class="flex flex-col justify-center items-center gap-4 lg:my-20">
        <h1 class="text-[#3c2480] sm:text-7xl text-4xl">Download Medne</h1>
        <p class="text-[#4B965A] text-xl mb-4">Available on All Platforms</p>
        <div class="flex items-center gap-8">
          <a href="https://apps.apple.com/us/app/medne/id6469580669" target="_blank">
            <img
              src="https://static.wixstatic.com/media/c837a6_00f0f6bdef734254a417de6143fd7ec6~mv2.png/v1/fill/w_272,h_92,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/c837a6_00f0f6bdef734254a417de6143fd7ec6~mv2.png"
              alt=""
              class="w-32 border border-white rounded-lg"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.Mende&hl=en&gl=US" target="_blank">
            <img
              src="https://static.wixstatic.com/media/c837a6_3a4391ec8d1441479689140f6f7d61aa~mv2.png/v1/fill/w_298,h_92,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/c837a6_3a4391ec8d1441479689140f6f7d61aa~mv2.png"
              alt=""
              class="w-32 border border-white rounded-lg"
            />
          </a>
        </div>
      </div>

      <TheFooter :data="banner" v-if="banner" />
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import TheFooter from "../components/ui/TheFooter.vue";
import CartNews from "../components/news/CartNews";
import QuizPart from "../components/home/QuizPart.vue";
import BidniiMerch from "@/components/home/BidniiMerch.vue";

export default {
  name: "HomePage",
  components: {
    TheFooter,
    CartNews,
    QuizPart,
    BidniiMerch,
  },

  setup() {
    const store = useStore();

    const categoryData = ref([]);
    const newsData = ref([]);
    const banner = ref([]);
    const error = ref(null);
    const perViewSwiper = ref(3);
    const navigationSwiper = ref(false);

    onMounted(async () => {
      try {
        await store.dispatch("home/fetchCategory");
        await store.dispatch("home/fetchNews");
        await store.dispatch("home/fetchBanners");

        categoryData.value = computed(() => store.getters["home/getCategory"]).value;
        newsData.value = computed(() => store.getters["home/getNews"]).value;
        banner.value = computed(() => store.getters["home/getHomeBanners"]).value;
      } catch (err) {
        error.value = "Алдаа гарлаа. Хуудсыг дахин ачааллана уу.";
        console.log(err);
      }
    });

    const updateSlidesPerView = () => {
      const breakpointSm = 520;
      const breakpointMd = 1024;
      const screenWidth = window.innerWidth;

      if (screenWidth < breakpointSm) {
        perViewSwiper.value = 1;
        navigationSwiper.value = true;
      } else if (screenWidth < breakpointMd) {
        perViewSwiper.value = 2;
      } else {
        perViewSwiper.value = 3;
      }
    };

    onMounted(() => {
      updateSlidesPerView();
      window.addEventListener("resize", updateSlidesPerView);
    });

    const receiveDataFromChild = (value) => {
      if (value) {
        const targetDiv = document.getElementById("merch");
        targetDiv.scrollIntoView({ behavior: "smooth" });
      }
    };

    return {
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
      categoryData,
      newsData,
      error,
      perViewSwiper,
      navigationSwiper,
      banner,
      receiveDataFromChild,
    };
  },
};
</script>

<style scoped>
.skeleton-loader {
  background-color: #3a324d;
  animation: skeleton-pulse 1.5s infinite ease-in-out alternate-reverse;
}

@keyframes skeleton-pulse {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
</style>
