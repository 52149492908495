<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4 font-medium text-sm md:text-lg lg:text-xl" v-if="data.length > 0">
    <a :href="`/newsDetail/${data[0].id}`" class="col-span-1">
      <div class="relative">
        <img :src="data[0].image" alt="" class="w-full h-48 sm:h-80 object-cover rounded-2xl" />
        <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-black opacity-60"></div>
        <div class="absolute bottom-1 left-0 w-full p-2 md:px-4">
          <p>{{ data[0].title }}</p>
          <span class="text-sm">{{ data[0].created_at }}</span>
        </div>
      </div>
    </a>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <a :href="`/newsDetail/${data[1].id}`">
        <div class="relative">
          <img :src="data[1].image" alt="" class="w-full h-48 sm:h-80 object-cover rounded-2xl" />
          <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-black opacity-60"></div>
          <div class="absolute bottom-1 left-0 w-full p-2 md:px-4">
            <p>{{ data[1].title }}</p>
            <span class="text-sm">{{ data[1].created_at }}</span>
          </div>
        </div>
      </a>
      <a :href="`/newsDetail/${data[2].id}`">
        <div class="relative">
          <img :src="data[2].image" alt="" class="w-full h-48 sm:h-80 object-cover rounded-2xl" />
          <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-black opacity-60"></div>
          <div class="absolute bottom-1 left-0 w-full p-2 md:px-4">
            <p>{{ data[2].title }}</p>
            <span class="text-sm">{{ data[2].created_at }}</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  setup() {},
};
</script>
