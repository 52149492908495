<template>
  <admin-page>
    <div class="px-8 py-4">
      <!-- <div class="flex justify-center items-center h-screen" v-if="isLoading">
        <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div> -->
      <div class="flex flex-col items-center h-screen">
        <h2 class="text-center mb-4">Тоглогчид</h2>
        <canvas id="myChart"></canvas>
      </div>
    </div>
  </admin-page>
</template>

<script>
import { onMounted, onUnmounted, ref, reactive } from "vue";
import { adminInstance } from "@/libs/api/admin";
import Chart from "chart.js/auto";

export default {
  setup() {
    const data = reactive({
      labels: [],
      datasets: [
        {
          label: "Оноо",
          data: [],
          backgroundColor: ["rgba(153, 102, 255, 0.2)"],
          borderColor: ["rgb(153, 102, 255)"],
          borderWidth: 1,
          barPercentage: 0.5,
          hoverBackgroundColor: "rgba(153, 102, 255, 0.4)",
        },
      ],
    });

    const interval = ref(null);
    // const isLoading = ref(false);

    onMounted(() => {
      fetchUsersScores();
      interval.value = setInterval(() => {
        fetchUsersScores();
      }, 5000);
    });

    onUnmounted(() => {
      if (interval.value) clearInterval(interval.value);
    });

    // setup
    const MAX_RETRIES = 3;
    const RETRY_DELAY = 2000;

    const fetchUsersScores = async (retries = MAX_RETRIES) => {
      try {
        // isLoading.value = true;
        const response = await adminInstance.get("/user/scores");
        data.labels = response.data.map((user) => user?.user_name || "Нэргүй");
        data.datasets[0].data = response.data.map((user) => user.score);
        createChart();
        // isLoading.value = false;
      } catch (error) {
        if (retries > 0) {
          setTimeout(() => fetchUsersScores(retries - 1), RETRY_DELAY);
        } else {
          // isLoading.value = false;
          console.error(error);
        }
      }
    };

    // config
    const config = {
      type: "bar",
      data: data,
      options: {
        indexAxis: "y",
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    };

    // methods
    let myChart = null;
    const MAX_CHART_RETRIES = 3;
    const CHART_RETRY_DELAY = 2000;

    const createChart = (retries = MAX_CHART_RETRIES) => {
      try {
        if (!myChart) {
          myChart = new Chart(document.getElementById("myChart"), config);
        } else {
          myChart.data.labels = data.labels;
          myChart.data.datasets[0].data = data.datasets[0].data;
          myChart.update();
        }
      } catch (error) {
        if (retries > 0) {
          setTimeout(() => createChart(retries - 1), CHART_RETRY_DELAY);
        } else {
          // isLoading.value = false;
          console.log(error);
        }
      }
    };

    return {
      // isLoading,
    };
  },
};
</script>
