<template>
  <div>
    <!-- game end -->
    <transition name="fade">
      <div class="h-full">
        <div class="p-8 w-full">
          <h1 class="text-2xl font-semibold mb-4 text-center text-[#F2F2F2]">Тоглоом дууслаа</h1>
          <div class="mb-4">
            <div class="text-[#F2F2F2] mb-4">
              <p class="font-semibold text-center">Та нийт {{ gameScore }} асуултанд хариуллаа</p>
            </div>

            <div class="flex justify-center items-center">
              <button
                type="submit"
                class="text-[#F2F2F2] p-2 rounded-md bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-indigo-300"
                @click="restartGame"
              >
                Дахин эхлэх
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "GameEnd",
  props: {
    gameScore: {
      type: Number,
      required: true,
    },
    // questionLength: {
    //   type: Number,
    //   required: true,
    // },
  },

  setup(props, { emit }) {
    const restartGame = () => {
      console.log("clicked");

      emit("restartGame");
    };

    return {
      restartGame,
    };
  },
};
</script>
