import axios from "axios";

let baseURL = "";

// if (process.env.NODE_ENV === "production") {
  baseURL = "https://medne.mn/api/admin";
// } else {
//   // baseURL = "http://localhost/api/admin";
//   baseURL = "https://medne.mn/api/admin";
// }

export const adminInstance = axios.create({
  baseURL,
  withCredentials: true,
});

adminInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (process.env.NODE_ENV !== "production") {
      console.error(error); // This will only log errors when not in production.
    }
    return Promise.reject(error);
  }
);
