import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faChevronRight, faChevronLeft, faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import AdminPage from "./pages/admin/AdminPage.vue";
import UserContainer from "./pages/user/UserContainer.vue";
// import NavbarComp from "./components/ui/NavbarComp.vue";
import TheNav from "./components/ui/TheNav.vue";
import "./index.css";
const app = createApp(App);

app.use(router);
app.use(store);

library.add(faChevronRight, faChevronLeft, faUser, faLock);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("AdminPage", AdminPage);
app.component("UserContainer", UserContainer);
app.component("NavbarComp", TheNav);

app.mount("#app");
