<template>
  <div class="h-screen">
    <div class="flex justify-center items-center">
      <div class="flex flex-col justify-center items-center">
        <div class="flex justify-center items-center">
          <img src="https://i.imgur.com/qIufhof.png" alt="" class="w-96 h-96" />
        </div>
        <div class="flex justify-center items-center">
          <p class="text-4xl font-bold text-slate-100">404</p>
        </div>
        <div class="flex justify-center items-center">
          <p class="text-2xl font-bold text-slate-100">Хуудас олдсонгүй</p>
        </div>
        <div class="flex justify-center items-center mt-4">
          <a href="/" class="flex justify-center items-center">
            <button
              class="flex justify-center items-center bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              <span class="flex justify-center items-center">Нүүр хуудас руу буцах</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
