<template>
  <div class="flex h-screen">
    <div class="bg-gray-800 text-white w-64 flex flex-col">
      <div class="flex items-center justify-center h-16 bg-blue-900">
        <span class="text-lg font-bold">Admin</span>
      </div>
      <nav class="flex-1">
        <ul class="p-4">
          <li class="mb-4">
            <a href="/admin/add-question" class="flex items-center text-gray-300 hover:text-white uppercase text-sm">Асуулт нэмэх</a>
          </li>
          <li class="mb-4">
            <a href="/admin/question-list" class="flex items-center text-gray-300 hover:text-white uppercase text-sm">Асуултын жагсаалт</a>
          </li>
          <li class="mb-4">
            <a href="/admin/userlist" class="flex items-center text-gray-300 hover:text-white uppercase text-sm">Хэрэглэгчдийн жагсаалт</a>
          </li>
          <li class="mb-4">
            <a href="/admin/add-competition" class="flex items-center text-gray-300 hover:text-white uppercase text-sm">Тэмцээн</a>
          </li>
          <li class="mb-4">
            <button
              class="flex items-center justify-between text-gray-300 hover:text-white uppercase text-sm relative"
              @click="toggleDropDown('chart')"
            >
              <span> График </span>
              <span :class="{ 'arrow-rotate': dropDownStates.chart }" class="arrow ml-2"></span>
            </button>
            <transition name="fade" mode="out-in">
              <ul v-if="dropDownStates.chart" class="mt-2 pl-2">
                <li class="mb-4">
                  <a href="/admin/chart" class="flex items-center text-slate-400 hover:text-white uppercase text-sm">Онооны график</a>
                </li>
                <li class="mb-4">
                  <a href="/admin/chartByRegion" class="flex items-center text-slate-400 hover:text-white uppercase text-sm">Бүсийн график</a>
                </li>
                <li class="mb-4">
                  <a href="/admin/chartByGender" class="flex items-center text-slate-400 hover:text-white uppercase text-sm">Хүйсийн график</a>
                </li>
                <li class="mb-4">
                  <a href="/admin/chartByQ&A" class="flex items-center text-slate-400 hover:text-white uppercase text-sm">Асуулт, хариултын график</a>
                </li>
                <li class="mb-4">
                  <a href="/admin/systemInfo" class="flex items-center text-slate-400 hover:text-white uppercase text-sm">Систем хяналтын график</a>
                </li>
              </ul>
            </transition>
          </li>

          <li class="mb-4">
            <a target="_blank" :href="newsUrl" class="flex items-center text-gray-300 hover:text-white uppercase text-sm">Мэдээ</a>
          </li>

          <li class="mb-4">
            <a href="/admin/add-banner" class="flex items-center text-gray-300 hover:text-white uppercase text-sm">Баннер нэмэх</a>
          </li>

          <li class="mb-4">
            <a href="/admin/bannerList" class="flex items-center text-gray-300 hover:text-white uppercase text-sm">Баннер жагсаалт</a>
          </li>

          <li class="mb-2">
            <button class="flex items-center text-gray-300 hover:text-white uppercase text-sm" @click="logout">Гарах</button>
          </li>
        </ul>
      </nav>
    </div>
    <div class="bg-white flex-1 overflow-y-scroll">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { adminInstance } from "@/libs/api/admin";
import { ref, reactive } from "vue";

export default {
  name: "AdminPage",
  setup() {
    const dropDownStates = reactive({
      chart: false,
    });
    let newsUrl = ref("");
    if (process.env.NODE_ENV === "production") {
      newsUrl.value = "http://medne.mn:3000/admin/newsadd";
    } else {
      newsUrl.value = "http://localhost:3000/api/newsadd";
    }

    const toggleDropDown = (dropDown) => {
      dropDownStates[dropDown] = !dropDownStates[dropDown];
    };

    const logout = async () => {
      try {
        await adminInstance.get("/logout");
        window.location.href = "/admin/login";
      } catch (e) {
        throw new Error(e);
      }
    };
    return {
      logout,
      toggleDropDown,
      dropDownStates,
      newsUrl,
    };
  },
};
</script>
<style>
.arrow {
  display: inline-block;
  margin-left: 5px;
  border: solid currentColor;
  border-width: 0 2px 2px 0;
  padding: 3px;
  /* This transform will have the arrow pointing down */
  transform: rotate(-45deg);
  transition: transform 0.3s ease-in-out;
}

/* Rotate the arrow to have it point up when the dropdown is active */
.arrow-rotate {
  transform: rotate(45deg);
}
</style>
