<template>
  <div class="border-[#3c3c3c] text-sm" v-if="data">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-2 py-6 border-t">
      <div class="flex flex-col sm:flex-row gap-10">
        <div>
          <p class="uppercase sm:pb-6 pb-2">Холбоо барих</p>
          <div class="flex flex-col justify-center gap-4">
            <div class="flex gap-3 sm:w-80 w-full text-justify">
              <img src="../../assets/location-pin.svg" alt="location" class="w-4 h-4 mt-1" />
              <p>Монгол улс, Улаанбаатар хот, Сүхбаатар дүүрэг, Энхтайваны өргөн чөлөө-1 Бизнес тауэр, 12 давхар, 1201 тоот</p>
            </div>
            <div class="flex gap-3 items-center">
              <img src="../../assets/call.svg" alt="contact" class="w-4 h-4" />
              <p>77001174, 88106140</p>
            </div>
            <div class="flex gap-3 items-center">
              <img src="../../assets/mail.svg" alt="mail" class="w-4 h-4" />
              <p>munkhbat@bowsys.mn</p>
            </div>
          </div>
        </div>
        <div>
          <p class="uppercase sm:pb-6 pb-2">Бидний тухай</p>
          <div class="flex flex-col justify-center gap-4">
            <a href="/policy">Үйлчилгээний нөхцөл</a>
            <a href="/faq">FAQ</a>
            <div class="flex flex-col gap-4 sm:mb-6">
              <div class="flex text-sm gap-4 items-center">
                <a href="https://www.instagram.com/medne.mn?igshid=NGVhN2U2NjQ0Yg%3D%3D&utm_source=qr" target="_blank">
                  <img src="../../assets/instagram.svg" alt="" class="w-6 h-6" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61553085023711&mibextid=LQQJ4d" target="_blank">
                  <img src="../../assets/facebook.svg" alt="" class="w-5 h-5" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img :src="data?.banner_img" alt="banner" class="w-full h-52 rounded-lg" v-if="data.banner_img" />
      <div
        class="w-full h-52 rounded-lg flex justify-center items-center space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse bg-gray-300 dark:bg-gray-700"
        v-else
      >
        <svg
          class="w-12 h-12 text-gray-200 dark:text-gray-600"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path
            d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
          />
        </svg>
      </div>
    </div>
    <div class="flex flex-col md:flex-row gap-2 justify-between items-center p-2 border-t">
      <p>©2023 Medne.mn Бүх эрх хуулиар хамгаалагдсан.</p>
      <a href="https://bowsys.mn" target="_blank"> Developed by BOW LLC </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
