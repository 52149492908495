<template>
  <div>
    <div class="flex justify-between py-4 border-y border-y-[#D6DCDC] text-[#D6DCDC]">
      <!-- <p class="text-[#f37c2b] text-base">{{ currentSortCriteria === "newest" ? "Сүүлд нийтлэгдсэн" : "Хамгийн их үзэлттэй" }}</p> -->
      <p>Хамгийн их үзэлттэй</p>

      <div class="flex gap-2">
        <button class="text-red-500 border-b-2 border-[#D6DCDC]">
          <!-- :class="{ 'text-red-500 border-b-2 border-red-500': sortByNewestPressed, 'text-blue-500': !sortByNewestPressed }" -->
          <!-- @click="sortByNewest" -->

          <!-- :fill="sortByNewestPressed ? 'red' : 'currentcolor'" -->
          <img src="../../assets/clock.svg" />
        </button>

        <!-- @click="sortByMostRead" -->
        <!-- <button :class="{ 'text-red-500 border-b-2 border-red-500': sortByMostReadPressed, 'text-blue-500': !sortByMostReadPressed }"> -->
        <button class="text-red-500 border-b-2 border-[#D6DCDC]">
          <!-- :fill="sortByMostReadPressed ? 'red' : 'currentColor'" -->
          <img src="../../assets/eye-2.svg" />
        </button>
      </div>
    </div>

    <div class="h-[300px] overflow-scroll">
      <div class="w-full">
        <div v-for="newsItem in data" :key="newsItem.id">
          <a class="border-b border-b-[#3a324d] block py-3 hover:scale-95" :href="`/newsDetail/${newsItem.id}`">
            <p>{{ newsItem?.title }}</p>
            <span class="items-center text-[#9a9a9f] flex text-xs mt-1 gap-[0.3rem]">
              <img src="../../assets/clock-2.svg" />

              {{ newsItem?.created_at?.split("T")[0] }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
