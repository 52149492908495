import axios from "axios";

export default {
  namespaced: true,
  state: {
    category: [],
    news: [],
    HomeBanner: {},
    NewsBanner: {},
  },
  getters: {
    getCategory(state) {
      return state.category;
    },
    getNews(state) {
      return state.news;
    },
    getHomeBanners(state) {
      return state.HomeBanner;
    },
    getNewsBanners(state) {
      return state.NewsBanner;
    },
  },
  mutations: {
    setCategory(state, payload) {
      state.category = payload;
    },
    setNews(state, payload) {
      state.news = payload;
    },
    setBanners(state, payload) {
      payload.map((item) => {
        if (item.banner_position === "Нүүр хуудас") {
          state.HomeBanner = item;
        } else {
          state.NewsBanner = item;
        }
      });
    },
  },
  actions: {
    async fetchCategory(context) {
      try {
        const response = await axios.get("https://medne.mn/api/site/category");
        context.commit("setCategory", response.data);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchNews(context) {
      try {
        const response = await axios.get("https://medne.mn/api/site/news");
        context.commit("setNews", response.data);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchBanners(context) {
      try {
        const response = await axios.get("https://medne.mn/api/site/banners");
        context.commit("setBanners", response.data);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
