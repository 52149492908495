<template>
  <admin-page>
    <div class="px-4" v-if="isLoaded">
      <!-- <div class="mb-4">Категориуд</div> -->
      <!-- <hot-table :settings="hotCategorySettings" :data="categoryList"></hot-table> -->
      <div class="mb-4 text-center">Асуултын жагсаалт</div>
      <hot-table :settings="hotSettings" :data="questionList"></hot-table>
    </div>
    <!-- else loading animation with tailwind-->
    <div v-else class="flex justify-center items-center h-screen">
      <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
    </div>
  </admin-page>
</template>

<script>
import { ref, onMounted } from "vue";
import { adminInstance } from "@/libs/api/admin";

import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
registerAllModules();
export default {
  name: "QuestionList",
  components: {
    HotTable,
  },

  setup() {
    const questionList = ref([]);
    const categoryList = ref([]);
    const isLoaded = ref(false);

    const getQuestionList = async () => {
      try {
        const question = await adminInstance.get("/question");
        const category = await adminInstance.get("/category");

        questionList.value = question.data;
        categoryList.value = category.data;
        isLoaded.value = true;
      } catch (err) {
        console.log(err);
      }
    };

    onMounted(() => {
      getQuestionList();
    });

    const hotSettings = {
      data: questionList.value,
      colHeaders: ["Асуулт", "Хариулт 1", "Хариулт 2", "Хариулт 3", "Хариулт 4", "Категори", "Зөв хариулт", "Шат"],
      columns: [
        {
          data: "question_text",
          type: "text",
          width: 300,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "all_options",
          type: "text",
          width: 150,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center capitalize">${value[0]}</div>`;
          },
        },
        {
          data: "all_options",
          type: "text",
          width: 150,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center capitalize">${value[1]}</div>`;
          },
        },
        {
          data: "all_options",
          type: "text",
          width: 150,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center capitalize">${value[2]}</div>`;
          },
        },
        {
          data: "all_options",
          type: "text",
          width: 150,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center capitalize">${value[3]}</div>`;
          },
        },
        {
          data: "category",
          type: "text",
          width: 150,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center ">${value}</div>`;
          },
        },
        {
          data: "correct_answer",
          type: "text",
          width: 150,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
        {
          data: "difficulty",
          type: "numeric",
          width: 100,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
      ],
      stretchH: "all",
      width: "100%",
      autoWrapRow: true,
      height: "auto",
      maxRows: 1200,
      rowHeaders: true,
      manualRowResize: true,
      manualColumnResize: true,
      manualRowMove: true,
      manualColumnMove: true,
      rowHeights: 30,
      colWidths: 150,
      rowHeaderWidth: 50,
      contextMenu: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const hotCategorySettings = {
      colHeaders: ["Категори", "Шат"],
      columns: [
        {
          data: "name",
          type: "dropdown",
          source: categoryList.value.map((item) => item.name),
          width: 300,
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            td.classList.add("htCenter", "htMiddle");
            td.innerHTML = `<div class="flex justify-center items-center">${value}</div>`;
          },
        },
      ],
    };

    return {
      questionList,
      categoryList,
      isLoaded,
      hotSettings,
      hotCategorySettings,
    };
  },
};
</script>
