<template>
  <navbar-comp></navbar-comp>

  <div class="bg-[#0A0021] font-sans text-[#D6DCDC] py-12">
    <div class="xl:mx-20 md:mx-8 mx-2">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mt-12" v-if="newsData.length > 0">
        <div v-for="(item, index) in newsData" :key="index">
          <a :href="`/newsDetail/${item.id}`" class="flex flex-col gap-4">
            <img :src="item.image" alt="" class="w-full h-60" />
            <p>{{ item.title }}</p>
            <p class="text-xs text-gray-600">{{ item.created_at?.split("T")[0] }}</p>
          </a>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-6 my-6" v-else>
        <div class="flex flex-col gap-4">
          <div class="h-64 skeleton-loader flex justify-center items-center">
            <svg
              class="w-12 h-12 text-gray-200 dark:text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path
                d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
              />
            </svg>
          </div>
          <div class="skeleton-loader h-6 w-full"></div>
        </div>
        <div class="flex flex-col gap-4">
          <div class="h-64 skeleton-loader flex justify-center items-center">
            <svg
              class="w-12 h-12 text-gray-200 dark:text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path
                d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
              />
            </svg>
          </div>
          <div class="skeleton-loader h-6 w-full"></div>
        </div>
        <div class="flex flex-col gap-4">
          <div class="h-64 skeleton-loader flex justify-center items-center">
            <svg
              class="w-12 h-12 text-gray-200 dark:text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path
                d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
              />
            </svg>
          </div>
          <div class="skeleton-loader h-6 w-full"></div>
        </div>

        <div class="flex flex-col gap-4">
          <div class="h-64 skeleton-loader flex justify-center items-center">
            <svg
              class="w-12 h-12 text-gray-200 dark:text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path
                d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
              />
            </svg>
          </div>
          <div class="skeleton-loader h-6 w-full"></div>
        </div>
        <div class="flex flex-col gap-4">
          <div class="h-64 skeleton-loader flex justify-center items-center">
            <svg
              class="w-12 h-12 text-gray-200 dark:text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path
                d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
              />
            </svg>
          </div>
          <div class="skeleton-loader h-6 w-full"></div>
        </div>
        <div class="flex flex-col gap-4">
          <div class="h-64 skeleton-loader flex justify-center items-center">
            <svg
              class="w-12 h-12 text-gray-200 dark:text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path
                d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
              />
            </svg>
          </div>
          <div class="skeleton-loader h-6 w-full"></div>
        </div>
      </div>
      <TheFooter :data="banner" v-if="banner" class="mt-32" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

import TheFooter from "../components/ui/TheFooter.vue";

export default {
  name: "NewsList",
  components: { TheFooter },
  setup() {
    const store = useStore();

    const newsData = ref([]);
    const banner = ref([]);
    const error = ref(null);

    onMounted(async () => {
      try {
        await store.dispatch("home/fetchNews");
        await store.dispatch("home/fetchBanners");

        newsData.value = computed(() => store.getters["home/getNews"]).value;
        banner.value = computed(() => store.getters["home/getHomeBanners"]).value;
      } catch (err) {
        error.value = "Алдаа гарлаа. Хуудсыг дахин ачааллана уу.";
        console.log(err);
      }
    });
    return { banner, newsData };
  },
};
</script>

<style scoped>
.skeleton-loader {
  background-color: #3a324d;
  animation: skeleton-pulse 1.5s infinite ease-in-out alternate-reverse;
}

@keyframes skeleton-pulse {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
</style>
