<template>
  <navbar-comp></navbar-comp>
  <!-- <div v-if="error" class="flex justify-center items-center h-screen">
      <p class="text-[#330E62] text-xl">{{ error }}</p>
    </div> -->

  <div class="bg-[#0A0021] font-sans text-[#D6DCDC] py-12">
    <div class="xl:mx-20 md:mx-8 mx-2">
      <div v-if="!isLoaded">
        <div class="md:grid grid-cols-12 gap-8">
          <div class="col-span-7">
            <div class="skeleton-loader h-8 w-56"></div>
            <div class="skeleton-loader h-4 w-16 my-6"></div>
          </div>
        </div>
        <div class="md:grid grid-cols-12 gap-8">
          <div class="col-span-7">
            <div class="skeleton-loader w-full h-64 flex justify-center items-center">
              <svg
                class="w-12 h-12 text-gray-200 dark:text-gray-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 18"
              >
                <path
                  d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
                />
              </svg>
            </div>
            <div class="skeleton-loader h-6 mt-10"></div>
            <div class="skeleton-loader h-6 mt-4"></div>
            <div class="skeleton-loader h-6 mt-4"></div>
            <div class="skeleton-loader h-6 mt-4"></div>

            <div class="flex gap-6 mt-8">
              <div class="skeleton-loader h-6 w-full"></div>
              <div class="skeleton-loader h-6 w-full"></div>
            </div>
          </div>
          <div class="col-span-5 hidden md:block">
            <!-- <SideNews :data="newsList" /> -->
            <div class="skeleton-loader h-10 w-full"></div>
            <div class="skeleton-loader h-10 w-full mt-4"></div>
            <div class="skeleton-loader h-10 w-full mt-4"></div>
            <div class="skeleton-loader h-10 w-full mt-4"></div>
            <div class="skeleton-loader h-10 w-full mt-4"></div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="md:grid grid-cols-12 gap-8">
          <div class="col-span-7">
            <p class="text-4xl">{{ news.title }}</p>
            <div class="flex gap-3 items-center my-4 text-gray-500 text-sm">
              <img src="../assets/clock-2.svg" alt="" class="w-5 h-5" />
              <p>{{ news.created_at?.split("T")[0] }}</p>
            </div>
          </div>
        </div>
        <div class="md:grid grid-cols-12 gap-8">
          <div class="col-span-7">
            <img :src="news.image" alt="" class="w-full h-56 md:h-96 object-fill rounded-sm" />
            <div v-html="news.text" class="text-justify my-10"></div>

            <div class="flex gap-4 justify-center mb-4 w-full">
              <a href="https://www.facebook.com/profile.php?id=61553085023711&mibextid=LQQJ4d" target="_blank" class="w-full">
                <div aria-label="facebook" class="h-full bg-[rgb(62,91,169)] flex items-center rounded-sm justify-center p-2 gap-[8px]">
                  <img src="../assets/facebook.svg" alt="ii" />

                  Хуваалцах
                </div>
              </a>
              <div aria-label="twitter" class="w-full h-full bg-black flex items-center rounded-sm justify-center p-2 gap-[8px]">Жиргэх</div>
            </div>
          </div>
          <div class="col-span-5 hidden md:block">
            <!-- <div v-if="banner" class="mb-4">
            <img :src="banner.banner_img" alt="" class="w-full h-64" />
          </div> -->
            <SideNews :data="newsList" />
          </div>
        </div>
      </div>

      <TheFooter :data="banner" v-if="banner" class="mt-24" />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { clientInstance } from "@/libs/api/client";
import SideNews from "@/components/news/SideNews.vue";
import TheFooter from "@/components/ui/TheFooter.vue";

export default {
  name: "NewsDetail",
  components: { SideNews, TheFooter },
  setup() {
    const store = useStore();
    const route = useRoute();

    const news = ref([]);
    const newsList = ref([]);
    const banner = ref({});

    const isLoaded = ref(false);
    const error = ref(null);

    onMounted(async () => {
      try {
        const response = await clientInstance.get(`/site/news/${route.params.id}`);
        await store.dispatch("home/fetchNews");

        news.value = response.data[0];
        newsList.value = computed(() => store.getters["home/getNews"]).value;

        await store.dispatch("home/fetchBanners");

        // banner.value = computed(() => store.getters["home/getNewsBanners"]).value;
        banner.value = computed(() => store.getters["home/getHomeBanners"]).value;

        isLoaded.value = true;
      } catch (err) {
        error.value = "Алдаа гарлаа. Хуудсыг дахин ачааллана уу.";
        isLoaded.value = true;
        console.log(err);
      }
    });

    return {
      news,
      newsList,
      isLoaded,
      error,
      banner,
    };
  },
};
</script>

<style scoped>
.skeleton-loader {
  background-color: #3a324d;
  animation: skeleton-pulse 1.5s infinite ease-in-out alternate-reverse;
}

@keyframes skeleton-pulse {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
</style>
