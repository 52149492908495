import { questions } from "../dummyData.js";
import { gamePlayInstance } from "@/libs/api/gameplay.js";

export default {
  namespaced: true,
  state: {
    question: questions,
    isCorrect: false,
    difficulty: 1,
    category: null,
    questionId: null,
  },

  getters: {
    getQuestion(state) {
      return state.question;
    },
  },

  mutations: {
    setQuestion(state, payload) {
      state.question = payload;
    },
    setIsCorrect(state, payload) {
      state.isCorrect = payload;
    },
    setDifuclty(state, payload) {
      state.difficulty = payload;
    },
    setCategory(state, payload) {
      state.category = payload;
    },
    setQuestionId(state, payload) {
      state.questionId = payload;
    },
  },

  actions: {
    // FETCH RANDOM QUESTION BY CATEGORY
    async fetchRandomQuestion({ commit }, payload) {
      try {
        const queryParams = new URLSearchParams({
          category: payload.categoryId,
          difficulty: payload.difficulty,
          correct: payload.isCorrect,
          questionId: payload.questionId,
        });
        const response = await gamePlayInstance.get(`/question?${queryParams}`);
        commit("setQuestion", response.data);

        return response;
      } catch (error) {
        return error;
      }
    },

    // CHECK ANSWER AND RECORD USER ANSWER TO DATABASE IF CORRECT
    async checkAnswer(_, { questionId, answer, categoryId }) {
      try {
        const response = await gamePlayInstance.post("/check/answer", { questionId, answer, categoryId });
        return response?.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
