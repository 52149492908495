import { gamePlayInstance } from "@/libs/api/gameplay.js";
import { category } from "../dummyData.js";

export default {
  namespaced: true,
  state: {
    category: category,
  },
  getters: {
    getCategory(state) {
      return state.category;
    },
  },
  mutations: {
    setCategory(state, payload) {
      state.category = payload;
    },
  },
  actions: {
    async fetchCategory(context) {
      try {
        const response = await gamePlayInstance.get("/category");
        context.commit("setCategory", response.data);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
