export const category = [
  {
    id: 1,
    name: "Уламжлал",
    totalQuestion: 10,
    totalAnswered: 0,
    image: "history.png",
  },
  {
    id: 2,
    name: "Спорт",
    totalQuestion: 30,
    totalAnswered: 0,
    image: "sports.png",
  },
  {
    id: 3,
    name: "Улс төр",
    totalQuestion: 20,
    totalAnswered: 0,
    image: "alterEgo.png",
  },
];

export const questions = [
  {
    id: 1,
    categoryId: 1,
    question: "12 жилийн 10дахь амьтан юу вэ?",
    options: [
      {
        id: 1,
        option: "Тахиа",
      },
      {
        id: 2,
        option: "Хонь",
      },
      {
        id: 3,
        option: "Үхэр",
      },
      {
        id: 4,
        option: "Луу",
      },
    ],
    answer: 1,
  },
  {
    id: 4,
    categoryId: 1,
    question: "Сайн санааны үзүүрт",
    options: [
      {
        id: 1,
        option: "Сайн",
      },
      {
        id: 2,
        option: "Алт",
      },
      {
        id: 3,
        option: "Мөнгө",
      },
      {
        id: 4,
        option: "Шар тос",
      },
    ],
    answer: 4,
  },
  {
    id: 5,
    categoryId: 1,
    question: "Хөөрөгний уут сав?",
    options: [
      {
        id: 1,
        option: "Хөөрөг",
      },
      {
        id: 2,
        option: "Уут",
      },
      {
        id: 3,
        option: "Сав",
      },
      {
        id: 4,
        option: "Даалин",
      },
    ],
    answer: 4,
  },
  {
    id: 6,
    categoryId: 1,
    question: "Малд хэдэн шагай байдаг вэ?",
    options: [
      {
        id: 1,
        option: "1",
      },
      {
        id: 2,
        option: "2",
      },
      {
        id: 3,
        option: "3",
      },
      {
        id: 4,
        option: "4",
      },
    ],
    answer: 2,
  },
  {
    id: 7,
    categoryId: 1,
    question: " Мэлмий гэж юуг хэлж байна вэ?",
    options: [
      {
        id: 1,
        option: "Хүн",
      },
      {
        id: 2,
        option: "Толгой",
      },
      {
        id: 3,
        option: "Нүд",
      },
      {
        id: 4,
        option: "Ухаан",
      },
    ],
    answer: 3,
  },
  {
    id: 2,
    categoryId: 2,
    question: "Монгол улс хэдэн онд олимпоос алтан медаль авсан вэ?",
    options: [
      {
        id: 1,
        option: "2002 он",
      },
      {
        id: 2,
        option: "2000 он",
      },
      {
        id: 3,
        option: "2008 он",
      },
      {
        id: 4,
        option: "2012 он",
      },
    ],
    answer: 3,
  },
  {
    id: 3,
    categoryId: 3,
    question: "Монгол улсын анхны ерөнхийлөгч хэн бэ?",
    options: [
      {
        id: 1,
        option: "Дамдин",
      },
      {
        id: 2,
        option: "Сүхбаатар",
      },
      {
        id: 3,
        option: "Очирбат",
      },
      {
        id: 4,
        option: "Цэцэгдорж",
      },
    ],
    answer: 3,
  },
];
