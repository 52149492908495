import { clientInstance } from "@/libs/api/client";
export default {
  namespaced: true,
  state: {
    user: {
      userName: "",
    },
    token: null,
  },
  getters: {
    getUserName(state) {
      if (!localStorage.getItem("user")) {
        return state.user.userName;
      } else {
        return localStorage.getItem("user");
      }
    },
  },
  mutations: {
    setUserName(state, payload) {
      state.user.userName = payload;
    },
  },
  actions: {
    async login({ commit }, payload) {
      try {
        const response = await clientInstance.post("/user/auth/login", payload);
        commit("setUserName", response.data.user_name);
        localStorage.setItem("user", response.data.user_name);
        localStorage.setItem("expiresAt", response.data.expiresAt);
        return response;
      } catch (error) {
        console.error(error);
        return error.response;
      }
    },

    async googleLogin({ commit }, payload) {
      try {
        const response = await clientInstance.post("/user/auth/login/google", payload);
        if (response.data.is_entered_info) {
          commit("setUserName", response.data.user_name);
          localStorage.setItem("user", response.data.user_name);
          localStorage.setItem("expiresAt", response.data.expiresAt);
        }
        return response;
      } catch (error) {
        console.error(error);
        return error.response;
      }
    },
  },
};
