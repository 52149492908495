import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";

import HomePage from "./pages/HomePage.vue";
import GameCategory from "./pages/GameCategory.vue";
import GamePlay from "./pages/GamePlay.vue";
// import ChartPage from "./pages/ChartPage.vue";
import NewsDetail from "./pages/NewsDetail.vue";
import NewsList from "./pages//NewsList.vue";
import FeedBack from "./pages/FeedBack.vue";

// signup and login pages
import LoginPage from "./pages/LoginPage.vue";

// user pages
import UserQuestion from "./pages/user/UserQuestion.vue";
import UserProfile from "./pages/user/UserProfile.vue";

//Jishee1
import Jishee1 from "./pages/Jishee1/HelloWorld.vue";

// admin pages
import AdminLogin from "./pages/admin/AdminLogin.vue";
import AddQuestion from "./pages/admin/AddQuestion.vue";
import AddCompetition from "./pages/admin/AddCompetition.vue";
import AdminUser from "./pages/admin/AdminUser.vue";
import QuestionList from "./pages/admin/QuestionList.vue";
import ChartPage from "./pages/admin/ChartPage.vue";
import ChartByRegion from "./pages/admin/ChartByRegion.vue";
import AddBanner from "./pages/admin/AddBanner.vue";
import BannerList from "./pages/admin/BannerList.vue";
import ChartByGender from "./pages/admin/ChartByGender.vue";
import ChartByQuestionAndAnswer from "./pages/admin/ChartByQuestionAndAnswer.vue";
import ChartBySystemInfo from "./pages/admin/ChartBySystemInfo.vue";

// 404 page
import NotFound from "./pages/NotFound.vue";

const publicRoutes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/newsDetail/:id",
    component: NewsDetail,
  },
  { path: "/newsList", component: NewsList },
  { path: "/feedback", component: FeedBack },
  {
    path: "/login",
    component: LoginPage,
  },
  {
    path: "/login/token=:token",
    component: LoginPage,
  },
  {
    path: "/chart",
    component: ChartPage,
  },
  {
    path: "/jishee1",
    component: Jishee1,
  },
];

const userRoutes = [
  {
    path: "/user/question",
    component: UserQuestion,
    meta: {
      title: "",
      requiresAuth: true,
      checkIsEnteredInfo: true,
    },
  },
  {
    path: "/user/profile",
    component: UserProfile,
    meta: {
      title: "",
      requiresAuth: true,
      checkIsEnteredInfo: true,
    },
  },
  {
    path: "/game/category",
    component: GameCategory,
    meta: {
      title: "Ангилал сонгох",
      requiresAuth: true,
      checkUserInfoEntered: true,
    },
  },
  {
    path: "/game/play/cid=:id",
    component: GamePlay,
    meta: {
      title: "Тоглоом",
      requiresAuth: true,
      checkUserInfoEntered: true,
    },
  },
];

const adminRoutes = [
  {
    path: "/admin",
    component: QuestionList,
    meta: {
      title: "Асуултууд",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/login",
    component: AdminLogin,
    meta: {
      title: "Админ нэвтрэх",
    },
  },
  {
    path: "/admin/add-question",
    component: AddQuestion,
    meta: {
      title: "Асуулт нэмэх",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/add-competition",
    component: AddCompetition,
    meta: {
      title: "Тэмцээн нэмэх",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/question-list",
    component: QuestionList,
    meta: {
      title: "Асуултууд",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/userlist",
    component: AdminUser,
    meta: {
      title: "Хэрэглэгчид",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/chart",
    component: ChartPage,
    meta: {
      title: "Хэрэглэгчид",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/chartByRegion",
    component: ChartByRegion,
    meta: {
      title: "Хэрэглэгчид",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/add-banner",
    component: AddBanner,
    meta: {
      title: "Баннер",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/bannerList",
    component: BannerList,
    meta: {
      title: "Баннер",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/chartByGender",
    component: ChartByGender,
    meta: {
      title: "График",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/chartByQ&A",
    component: ChartByQuestionAndAnswer,
    meta: {
      title: "График",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/admin/systemInfo",
    component: ChartBySystemInfo,
    meta: {
      title: "График",
      requiresAdminAuth: true,
    },
  },
];

const otherRoutes = [
  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
  },
];

const routes = [...publicRoutes, ...userRoutes, ...adminRoutes, ...otherRoutes];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

let baseUrl = "";
if (process.env.NODE_ENV === "production") {
  baseUrl = "https://medne.mn";
} else {
  baseUrl = "http://localhost";
}

let adminUrl = "";
// if (process.env.NODE_ENV === "production") {
adminUrl = "https://medne.mn/api/admin/checkauth/token";
// } else {
// adminUrl = "http://localhost/api/admin/checkauth/token";
//   // adminUrl = "https://medne.mn/api/admin/checkauth/token";
// }

async function checkUserAuth() {
  try {
    const res = await axios.get(baseUrl + process.env.VUE_APP_CHECK_AUTH, {
      withCredentials: true,
    });
    return res.data.valid;
  } catch (err) {
    localStorage.removeItem("user");
    localStorage.removeItem("isuser");
    return false;
  }
}

function checkUserExpired() {
  const expiresAt = JSON.parse(localStorage.getItem("expiresAt"));
  const currentTime = new Date().getTime();

  if (expiresAt < currentTime) {
    localStorage.removeItem("user");
    localStorage.removeItem("isuser");
    localStorage.removeItem("expiresAt");
  }
}

async function checkAdminAuth() {
  try {
    const res = await axios.get(adminUrl, {
      withCredentials: true,
    });

    if (res.data.valid) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

async function checkUserInfoEntered() {
  try {
    const res = await axios.get(baseUrl + process.env.VUE_APP_CHECK_IS_ENTERED_INFO, {
      withCredentials: true,
    });
    if (res.data.valid) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

function setDocumentTitle() {
  document.title = "Medne";
}
setDocumentTitle();
router.beforeEach(async (to, from, next) => {
  checkUserExpired();
  if (to.path === "/admin") {
    if (await checkAdminAuth()) {
      return next("/admin/question-list");
    } else {
      return next("/admin/login");
    }
  }

  if (to.meta.requiresAdminAuth && !(await checkAdminAuth())) {
    return next("/admin/login");
  }

  if (to.path === "/admin/login" && (await checkAdminAuth())) {
    return next("/admin/question-list");
  }

  if (to.meta.requiresAuth && !(await checkUserAuth())) {
    return next("/login");
  }

  if (to.meta.checkIsEnteredInfo) {
    const isUserInfoEntered = await checkUserInfoEntered();

    if (isUserInfoEntered) {
      if (to.path !== "/user/profile") {
        return next("/user/profile");
      }
      return next();
    } else {
      if (to.path !== "/user/question") {
        return next("/user/question");
      }
      return next();
    }
  }

  if (to.path.includes("/game") && !(await checkUserInfoEntered())) {
    return next("/user/question");
  }

  if (to.path.includes("/login") && !to.path.includes("/admin/login") && (await checkUserAuth())) {
    return next("/");
  }

  next();
});

export default router;
