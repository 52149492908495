<template>
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0 relative">
    <!-- USER QUESTION -->
    <transition
      name="fade"
      enter-active-class="transition duration-300 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-300 ease-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div class="w-full rounded-lg shadow dark:border md:mt-0 sm:max-w-2xl xl:p-0 bg-bg1 bg-opacity-80 dark:border-gray-700 overflow-scroll">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">Хувийн мэдээлэл</h1>
          <form class="w-full grid grid-cols-1 md:grid-cols-2 gap-5 md:col-span-4" action="#">
            <div>
              <label for="lastName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Овог</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="user"
                @input="handleFormInput"
              />
              <span class="text-xs text-red-500" v-if="error.lastName">Таны овог хоосон байна </span>
            </div>
            <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Нэр</label>
              <input
                type="text"
                name="userName"
                id="userName"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="user"
                @input="handleFormInput"
              />
              <span class="text-xs text-red-500" v-if="error.userName">{{ error.userName }}</span>
            </div>
            <div>
              <label for="registerNumber" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Регистрийн дугаар</label>
              <input
                type="text"
                name="registerNumber"
                id="registerNumber"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="ХХ00000000"
                @input="handleFormInput"
              />
              <span class="text-xs text-red-500" v-if="error.registerNumber">{{ error.registerNumber }}</span>
            </div>
            <div>
              <label for="phoneNumber" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Утасны дугаар</label>
              <input
                type="number"
                name="phoneNumber"
                id="phoneNumber"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="88888888"
                @input="handleFormInput"
              />
              <span class="text-xs text-red-500" v-if="error.phoneNumber">{{ error.phoneNumber }}</span>
            </div>
            <div>
              <label for="gender" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Хүйс </label>
              <select
                name="gender"
                id="gender"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                @input="handleFormInput"
              >
                <option value="" selected disabled hidden>Сонгох</option>
                <option value="Эрэгтэй">Эрэгтэй</option>
                <option value="Эмэгтэй">Эмэгтэй</option>
                <option value="Бусад">Бусад</option>
              </select>
              <span class="text-xs text-red-500" v-if="error.gender">{{ error.gender }}</span>
            </div>
            <div>
              <label for="position" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Албан тушаал</label>
              <select
                name="position"
                id="position"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                @input="handleFormInput"
              >
                <option value="" selected disabled hidden>Сонгох</option>
                <option value="Cурагч">ЕБС Cурагч</option>
                <option value="Оюутан">Оюутан</option>
                <option value="Ажил эрхлэгч">Ажил эрхлэгч</option>
                <option value="Төрийн ажилтан">Төрийн ажилтан</option>
                <option value="Шашны">Шашны</option>
                <option value="Ажилгүй">Ажилгүй</option>
                <option value="Бусад">Бусад</option>
              </select>
              <span class="text-xs text-red-500" v-if="error.position">{{ error.position }}</span>
            </div>

            <!-- ADDRESS -->
            <div>
              <label for="aimagHot" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Аймаг/Хот</label>
              <select
                name="aimagHot"
                id="aimagHot"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                @input="handleFormInput"
              >
                <option value="" selected disabled hidden>Сонгох</option>
                <option v-for="(item, index) in aimagHotArr" :key="index" :value="item.name">{{ item.name }}</option>
              </select>
              <span class="text-xs text-red-500" v-if="error.position">{{ error.aimagHot }}</span>
            </div>

            <div v-if="sumDuuregArr?.length > 0">
              <label for="sumDuureg" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Сум/Дүүрэг</label>
              <select
                name="sumDuureg"
                id="sumDuureg"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                @input="handleFormInput"
              >
                <option value="" selected disabled hidden>Сонгох</option>
                <option v-for="(item, index) in sumDuuregArr" :key="index" :value="item.name">{{ item.name }}</option>
              </select>
              <span class="text-xs text-red-500" v-if="error.position">{{ error.sumDuureg }}</span>
            </div>

            <div class="md:col-span-2">
              <label for="address" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Хаяг</label>
              <input
                type="text"
                name="address"
                id="address"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Хот, сум, баг, гудамж, хороо, байр, орц"
                @input="handleFormInput"
              />
              <span class="text-xs text-red-500" v-if="error.address">{{ error.address }}</span>
            </div>

            <div class="md:col-span-2 flex justify-center items-center">
              <button
                type="submit"
                class="flex items-center justify-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mt-4"
                @click="handleSubmit"
              >
                <button-loading v-if="isLoading"></button-loading>
                <span> Хадгалах </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, reactive, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { clientInstance } from "@/libs/api/client";
import ButtonLoading from "@/components/ui/ButtonLoading.vue";
import { useStore } from "vuex";
import { validateUserQuestionForm } from "@/libs/helper/validate";
export default {
  name: "UserQuestion",
  components: {
    ButtonLoading,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const aimagHotArr = ref([]);
    const sumDuuregArr = ref([]);
    const aimagId = ref(null);

    const form = reactive({
      lastName: "",
      userName: "",
      gender: "",
      position: "",
      phoneNumber: "",
      registerNumber: "",
      aimagHot: "",
      sumDuureg: "",
      address: "",
    });

    const error = reactive({
      lastName: "",
      userName: "",
      gender: "",
      position: "",
      phoneNumber: "",
      registerNumber: "",
      aimagHot: "",
      sumDuureg: "",
      address: "",
    });

    const isLoading = ref(false);

    onMounted(() => {
      fetchHotAimag();
    });

    const handleFormInput = (e) => {
      error[e.target.name] = "";
      if (e.target.name == "aimagHot") {
        form.sumDuureg = "";
        sumDuuregArr.value = [];
        aimagId.value = aimagHotArr.value.find((item) => item.name == e.target.value).id;
        form.aimagHot = e.target.value;
        console.log(form.aimagHot, aimagId.value);
      } else {
        form[e.target.name] = e.target.value;
      }
      console.log(form);
    };

    const fetchHotAimag = async () => {
      try {
        const res = await clientInstance.get("/user/address/aimaghot");
        if (res.status === 200) {
          aimagHotArr.value = res.data.aimagHot;
        }
      } catch (err) {
        console.log(err);
      }
    };

    watch(aimagId, (newVal) => {
      if (newVal) {
        fetchSumDuureg();
      }
    });

    const fetchSumDuureg = async () => {
      try {
        if (!form.aimagHot) return;
        const res = await clientInstance.get(`/user/address/sumduureg/${aimagId.value}`);
        if (res.status === 200) {
          sumDuuregArr.value = res.data.sumduureg;
          console.log(res.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const validate = validateUserQuestionForm(form);
      if (
        validate.lastName ||
        validate.userName ||
        validate.gender ||
        validate.position ||
        validate.phoneNumber ||
        validate.registerNumber ||
        validate.aimagHot ||
        validate.sumDuureg ||
        validate.address
      ) {
        error.lastName = validate.lastName;
        error.userName = validate.userName;
        error.gender = validate.gender;
        error.position = validate.position;
        error.phoneNumber = validate.phoneNumber;
        error.registerNumber = validate.registerNumber;
        error.aimagHot = validate.aimagHot;
        error.sumDuureg = validate.sumDuureg;
        error.address = validate.address;
        return;
      }
      try {
        isLoading.value = true;
        const res = await clientInstance.post("/user/register/user/info", form);
        if (res.status === 201) {
          store.commit("user/setUserName", res.data.user);
          localStorage.setItem("user", res.data.user);
          router.push("/");
        }
        isLoading.value = false;
      } catch (err) {
        console.log(err);
        isLoading.value = false;
      }
    };

    return {
      form,
      error,
      isLoading,
      aimagHotArr,
      sumDuuregArr,
      fetchHotAimag,
      handleFormInput,
      handleSubmit,
    };
  },
};
</script>
