import { adminInstance } from "@/libs/api/admin";

export default {
  namespaced: true,
  state: {
    // category: category,
    banners: [],
  },

  getters: {
    getCategory(state) {
      return state.category;
    },
    getBanners(state) {
      return state.banners;
    },
  },

  mutations: {
    setCategory(state, payload) {
      state.category = payload;
    },
    setBanners(state, payload) {
      state.banners = payload;
    },
  },

  actions: {
    async fetchCategory(context) {
      try {
        const response = await adminInstance.get("/category");
        console.log(response.data);
        context.commit("setCategory", response.data);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async insertUpdateDeleteBanner(context, payload) {
      try {
        const res = await adminInstance.post("/insertUpdateDeleteBanner", payload);
        return res;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchBanners(context) {
      try {
        const res = await adminInstance.get("/banner");
        context.commit("setBanners", res.data);
        return res.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
